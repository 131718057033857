import { EmptyState } from '../EmptyState/EmptyState';
import Button from '@mui/material/Button';
import DatabaseAdd from '@mui/icons-material/Add';
import React from 'react';


/**
 * @param {object} props
 * @param {() => void} props.onCreate
 */
export default function WelcomeToPageBlaze(props) {
  return <div>
    <EmptyState icon="MISSING" title="No sites created" description="Create a new site to get started" />
    <div style={{ textAlign: 'center', marginTop: 48 }}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => props.onCreate()}
        startIcon={<DatabaseAdd />}
        data-testid="create-new-space-button"
        size="large"
      >
        Create new site
      </Button>
    </div>
  </div>;
}