import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { store, isOrg } from '@store';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialogs, toast } from '../../message';
import { dialog } from '../DataDialog/DataDialog';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { Helmet } from 'react-helmet-async';
import { AUTH_URL_PREFIX, deleteTokenCookie, getTokenCookie } from '../../flags';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import PageInnerMain from './PageInnerMain';
import { PagePreviewEmbed } from '../PageBlaze/PagePreviewEmbed';
import { databaseDialogs } from '../Database/database_utilities';
import { PageEngineApp } from '../../../index_page_blaze';
import { StatusNotification } from '../StatusNotification/StatusNotification';
import { sitesTrashDialog } from '../PageBlaze/utils';


function PageAppInner() {
  useEffect(() => {
    const token = getTokenCookie();
    if (!token) {
      return;
    }
    signInWithCustomToken(getAuth(), token)
      .then(deleteTokenCookie)
      .catch(err => toast('Failed to login. Please try again after sometime. ' + err.message, {
        intent: 'danger'
      }));
  }, []);
  return (
    <>
      <Helmet defer={false}>
        <title>Page Blaze</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route exact path="/preview/" render={() => <ErrorBoundary>
          <PagePreviewEmbed />
        </ErrorBoundary>} />
        {/*this route is used only in local development, on production, firebase maps /p/ to the app engine cloud run's instance*/}
        <Route path={AUTH_URL_PREFIX}
          render={() => <ErrorBoundary>
            <PageEngineApp />
          </ErrorBoundary>}
        />
        <Route component={PageInnerMain} />
      </Switch>

      <AuthDialog />
      {dialogs({ store, isOrg })}
      <StatusNotification />
      {databaseDialogs()}
      {sitesTrashDialog()}
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default PageAppInner;
