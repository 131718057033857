import { exportString } from '../../import_export/DeltaExport';
import { searchSnippetsOrPages } from '../Search/sort_snippets';


/**
 * @param {PageObjectType} page
 * @returns {string}
 */
export const pageText = (page) => exportString(JSON.parse(JSON.stringify(page.current_revision.delta)), 'text').data;

/**
 * @param {PageObjectType[]} pages
 * @param {Object<string, SiteObjectType>} [groups]
 */
function processPages(pages, groups) {
  /**
     * @param {PageObjectType} page
     */
  function processPage(page) {
    return {
      snippet: {
        name: page.current_revision.name,
        shortcut: `/${page.slug}`,
        ...page,
      },
      text: pageText(page),
    };
  }
  return pages.map(processPage);
}

/**
 * Search in pages.
 *
 * @param {PageObjectType[]} snippets List of all snippets that need to be searched
 * @param {string[]} priorities unused
 * @param {string} query User query that was typed
 * @param {Object<string, SiteObjectType>} [groups] Mapping from site id to site object
 * @returns
 */
export function searchPages(snippets, priorities, query = '', groups = {}) {
  return searchSnippetsOrPages(snippets, priorities, processPages, query, groups);
}
