import React from 'react';
import { showDestructive, toast } from '../../message';
import { doSafeTableRequest } from '../../hooks/useTables';
import { PAGE_API_PREFIX } from '../../flags';
import T from '@mui/material/Typography';
import PageIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { TrashedSpacesDialog } from '../Database/TrashedSpacesDialog';
import Button from '@mui/material/Button';

/**
 * @param {object} props
 * @param {string} props.siteId
 */
export default function TrashedPagesDialog(props) {
  const pageTrashEndpoint = `${PAGE_API_PREFIX}/trash/site/${props.siteId}/`;
  return <TrashedSpacesDialog
    groupType="page"
    trashPaginatedEndpoint={pageTrashEndpoint}
    trashEndpoint={`${PAGE_API_PREFIX}/trash/`}
    labels={{
      groupType: 'page',
      groupTypeCapitalized: 'Page',
      groupTypePlural: 'pages',
    }}
    defaultIcon={PageIcon}
    hideDelete
    renderDialogActions={(data, refetch) => {
      if (!data?.length) {
        return null;
      }

      return (
        <Button
          size="small"
          onClick={async () => {
            showDestructive({
              contents: <>
                <T paragraph>Are you sure you want to permanently empty this site's trash? This cannot be undone.</T>
                <T>Type "empty" into the field below in order to proceed with deletion.</T>
              </>,
              confirmation: 'empty',
              confirmButtonText: 'Empty permanently',
              onDelete: async () => {
                try {
                  const deleteResponse = await doSafeTableRequest(pageTrashEndpoint,
                    'DELETE', null, { toastMessage: 'An error occurred emptying the site\'s trash.' });
                  if (deleteResponse?.error) {
                    toast(`Error emptying the site's trash: ${deleteResponse.detail}`, {
                      duration: 6000,
                      intent: 'danger'
                    });
                    return;
                  }
                } catch {
                  return;
                }

                await refetch();

                toast('Site\'s trash emptied.', {
                  intent: 'success'
                });
              }
            });

          }}
          color="error"
          variant="contained"
        >Empty Trash</Button>
      );
    }}
    urlHash="#site-trash"
  />;
}
