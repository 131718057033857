import React, { useState, useMemo, useEffect, useRef } from 'react';
import Zoom from '@mui/material/Zoom';
import { IconButton, Tooltip, ToggleButtonGroup, CircularProgress } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Smartphone';
import TabletIcon from '@mui/icons-material/Tablet';
import DesktopIcon from '@mui/icons-material/Computer';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import { PagePreviewFrame } from './PagePreviewEmbed';
import Dialog from '@mui/material/Dialog';
import RestoreIcon from '@mui/icons-material/Restore';
import Button from '@mui/material/Button';
import { store } from '@store';


/** @typedef {'desktop'|'tablet'|'phone'} PreviewDeviceType **/

/** @type {Record<PreviewDeviceType, React.CSSProperties>} */
const DEVICE_CONTAINER_STYLES = {
  desktop: {
    width: '100%',
    height: '100vh',
  },
  tablet: {
    width: 767,
    height: 'min(800px, 85vh)',
  },
  phone: {
    width: 360,
    height: 'min(640px, 85vh)',
  },
};


/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 * @param {PageObjectType} props.page
 * @param {function} props.onClose
 * @param {PageRevision=} props.revision
 * @param {() => Promise<void>=} props.onRestore
 */
export default function PagePreview(props) {
  const { site, page, revision } = props;
  const [device, setDevice] = useState(/** @type {PreviewDeviceType} **/'desktop');
  const [restoring, setRestoring] = useState(false);
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef(/** @type {HTMLIFrameElement} */ null);


  /**
   * @param {string} pageSlug
   * @returns {PageObjectType}
   */
  function getPageBySlug(pageSlug) {
    if (pageSlug) {
      return store.getState().sitesState.site.pages.find(p => p.slug === pageSlug);
    } else {
      return store.getState().sitesState.site.pages[0];
    }
  }

  /**
   * @param {string} pageSlug
   * @returns {import("./StandalonePage").PageData}
   */
  function generatePageData(pageSlug) {
    const isCurrentPage = props.page.slug === pageSlug;
    const page = isCurrentPage ? props.page : getPageBySlug(pageSlug);
    const revision = isCurrentPage ? props.revision : page.current_revision;
    return getPageData(page, revision);
  }

  /**
   * @param {PageObjectType} page
   * @param {PageRevision} revision
   * @returns {import("./StandalonePage").PageData}
   */
  function getPageData(page, revision) {
    const siteActivePages = site.pages.filter(p => p.is_active);
    return {
      siteId: site.id,
      siteSlug: site.slug,
      siteTitle: site.name,
      siteIcon: site.icon,
      siteConnected: site.connected,
      themeOverrides: site.theme_overrides,
      pageTitle: page.current_revision.name,
      pageSlug: page.slug,
      links: siteActivePages.filter(p => p.is_listed).map(p => ({
        title: p.current_revision.name,
        slug: p.slug,
      })),
      delta: revision?.delta || page.current_revision.delta || { ops: [] },
    };
  }

  useEffect(() => {
    const listener = async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const type = event.data.type;
      if (type === 'PAGE_LOADED') {
        setLoading(false);
      } else if (type === 'PAGE_PREVIEW') {
        iframeRef.current.contentWindow.postMessage({
          type: 'SET_PAGE_DATA',
          data: generatePageData(event.data.data),
        });
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
    // eslint-disable-next-line
  }, []);

  /** @type {import("./StandalonePage").PageData} */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const previewData = useMemo(() => getPageData(page, revision), [revision]);

  return <Dialog
    fullScreen
    open
    TransitionComponent={Zoom}
  >
    <PagePreviewFrame
      ref={iframeRef}
      loading={loading}
      data={previewData}
      containerStyle={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        boxShadow: device === 'desktop' ? undefined : '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 4px 6px 0px rgba(0,0,0,0.14), 0px 6px 12px 0px rgba(0,0,0,0.12)',
        backgroundColor: 'white',
        transition: 'all .5s ease-in-out',
        ...DEVICE_CONTAINER_STYLES[device],
      }}
    />
    <Box sx={{
      display: 'flex',
      position: 'absolute',
      right: 75,
      bottom: 50,
      borderRadius: 4,
      padding: '2px 8px',
      backgroundColor: 'black',
      '.MuiToggleButton-root, .MuiButton-root': {
        borderRadius: 4,
        color: '#969696',
        '&:hover': {
          color: '#dcdcdc',
        },
        '&.Mui-selected': {
          color: '#fff',
        },
        '&.Mui-disabled': {
          color: '#969696',
        },
      },
      '.MuiDivider-root': {
        borderColor: '#969696',
        width: 2,
      }
    }}>
      {/*TODO PB revisions*/}
      <ToggleButtonGroup
        disabled={restoring}
        value={device}
        exclusive
        onChange={(_, newDevice) => {
          if (newDevice) {
            setDevice(newDevice);
          }
        }}
        sx={{
          borderRadius: 4,
        }}
      >
        <ToggleButton value="desktop">
          <Tooltip title="Desktop">
            <DesktopIcon/>
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="tablet">
          <Tooltip title="Tablet">
            <TabletIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="phone">
          <Tooltip title="Phone">
            <PhoneIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>

      <Divider
        orientation="vertical"
        sx={{ margin: 0 }}
        flexItem
      />
      {!!revision && <Tooltip title="Restore revision">
        <Button
          disabled={restoring}
          onClick={async () => {
            try {
              setRestoring(true);
              await props.onRestore();
            } finally {
              setRestoring(true);
            }
          }}
          size="large"
        >
          {restoring ?
            <CircularProgress color="inherit" size={18} sx={{ marginRight: '10px' }} /> :
            <RestoreIcon sx={{ marginRight: '4px' }} />
          }
          Restore
        </Button>
      </Tooltip>}
      <Tooltip title="Exit">
        <IconButton
          disabled={restoring}
          sx={{
            margin: '0 1px',
            color: '#da5e5e',
            '&:hover': {
              color: '#dcdcdc',
            },
            '&.Mui-disabled': {
              color: '#969696',
            },
          }}
          onClick={() => props.onClose()}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Box>
  </Dialog>;
}
