import React from 'react';
import { Route } from 'react-router-dom';
import './inner_main.css';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import CommonMainPanel from '../MainPanel/CommonMainPanel';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import NotFound from '../NotFound/NotFound';
import { useTypedSelector } from '../../hooks';
import { isPageBlazeEnabled } from '../../flags';
import SiteList from '../PageBlaze/SitesList';
import SiteEditor from '../PageBlaze/SiteEditor';
import { KeyboardShortcutsDialog } from '../KeyboardShortcuts/KeyboardShortcutsDialog';


function InnerMain() {
  const enablePageBlaze = useTypedSelector(state => {
    if (!state.userState.isLoaded) {
      // avoid UI flicking
      return true;
    }

    return isPageBlazeEnabled(state);
  });

  if (!enablePageBlaze) {
    return <NotFound />;
  }

  return (
    <AuthenticatedRoutes>
      <KeyboardShortcutsDialog />

      <CommonMainPanel>
        <Route
          exact path="/"
          render={() => <ErrorBoundary>
            <SiteList />
          </ErrorBoundary>}
        />
        <Route
          exact path={[
            '/site/:id/page/:pid',
            '/site/:id/:tab?']}
          render={() => <ErrorBoundary>
            <SiteEditor />
          </ErrorBoundary>}
        />
      </CommonMainPanel>
    </AuthenticatedRoutes>
  );
}
export default InnerMain;