import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useUncommittedStore from '../../hooks/useUncommittedStore';
import { DatabaseMissing } from '../Database/DatabaseMissing';
import SnippetEditor from '../SnippetEditor/SnippetEditor';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import PageToolbar from './PageToolbar';
import SnippetWrapper from '../Snippet/SnippetWrapper';
import useDebouncedUpdate from '../../hooks/useDebouncedUpdate';
import { getPageUrl, restorePageRevision } from './apis';
import { store, usersSettingsRef } from '@store';
import DraftNotice from './DraftNotice';
import { storage } from '../../utilities';
import { useTypedSelectorShallowEquals } from '../../hooks';
import PagePreview from './PagePreview';
import useOnMount from '../../hooks/useOnMount';
import { beforeEmbeddedCommandClose } from '../SnippetEditor/editor_utilities';

const randomContentId = () => `${Date.now()}-${Math.random()}`;

export default function Page() {
  const { pid: pageId } = /** @type {{ pid?: string }} */ (useParams());
  const site = useUncommittedStore(store => store.sitesState.site);
  const page = useUncommittedStore(store => store.sitesState.pagesMap[pageId]);

  if (!site || !page) {
    return <DatabaseMissing itemType="page" />;
  }

  return <PageInner site={site} page={page} />;
}


/**
 * @typedef {object} PreviewProps
 * @param {boolean=} open
 * @param {PageRevision=} revision
 */


/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 * @param {PageObjectType} props.page
 */
function PageInner(props) {
  const { site, page } = props;
  const [showCommands, setShowCommands] = useState(false);
  const [previewState, setPreviewState] = useState(/** @type {PreviewProps} */ null);
  const { views, uid } = useTypedSelectorShallowEquals((state) => ({
    views: state.userState?.settingsLoaded ? (state.userState.views || {}) : null,
    uid: state.userState?.uid,
  }));
  const [contentId, setContentId] = useState(randomContentId());
  const debouncedPageUpdate = useDebouncedUpdate({
    storeFn: store => store.sitesState.pagesMap[page.id],
    endpointUrl: getPageUrl(page.id),
    dispatchObjectBase: {
      type: 'UPDATE_PAGE', pageId: page.id,
    },
  });
  const debouncedRevisionUpdate = useDebouncedUpdate({
    storeFn: store => store.sitesState.pagesMap[page.id].current_revision,
    endpointUrl: getPageUrl(page.id),
    dispatchObjectBase: {
      type: 'UPDATE_PAGE_REVISION', pageId: page.id,
    },
  });
  const history = useHistory();
  const hasEmbeddedCommandError = useRef(new Set());

  useOnMount(() => {
    const unblock = history.block((ref) => {
      
      const beforeCloseResponse = beforeEmbeddedCommandClose(hasEmbeddedCommandError.current);
      if (!beforeCloseResponse) {
        return;
      }
      beforeCloseResponse
        .then(() => {
          unblock();
          history.push(ref);
        })
        .catch(() => null);
      // stop proceeding
      return false;
    });
    return () => unblock();
  });

  useEffect(() => {
    if (views === null || !page || !page.current_revision.updated_on) {
      return;
    }

    // log the view if necessary
    let viewData = {};
    if ((
      // We haven't logged it yet so need to hide the created indicator
      !views[page.id]
      // We have logged it, but it has been updated by someone else so we need to hide the updated indicator
      || (
        new Date(page.current_revision.updated_on).getTime() > views[page.id] // It has been updated since last view
        && page.current_revision.updated_by !== uid  // And it was updated by someone else
      ))) {

      // We only log if it has been changed since the last view, and we weren't the one
      // updating it
      viewData[`views.${page.id}`] = Date.now();
    }
    if (!views[page.site_id]) {
      // We only log the first view for groups, not the most recent
      viewData[`views.${page.site_id}`] = Date.now();
    }
    if (Object.keys(viewData).length) {
      storage.update(usersSettingsRef, viewData, 'HIDE_AUTOSAVE');
    }

    // note this should only run once. If you allow it to run multiple times
    // e.g. (by using snippet instead of snippet.id as the dep), it will
    // have issues as updated_at can be null
    //
    // eslint-disable-next-line
  }, [page?.id, views === null]);

  /**
   * @param {Partial<Page>} updateData
   */
  async function updatePage(updateData) {
    try {
      await debouncedPageUpdate(updateData);
    } catch (error) {
      // TODO PB logs
    }
  }


  /**
   * @param {Partial<PageRevision>} updateData
  */
  async function updatePageRevision(updateData) {
    try {
      const updatedPage = await debouncedRevisionUpdate(updateData);
      store.dispatch({
        type: 'UPDATE_PAGE',
        pageId: updatedPage.id,
        data: updatedPage,
      });
    } catch (error) {
      // TODO PB logs
    }
  }

  useEffect(() => {
    setContentId(randomContentId());
  }, [page?.id]);

  const isEditable = ['owner', 'editor'].includes(site.user_permission); /** TODO PB && !connectedEditingBlocked;**/
  const showDraftNotice = () => isEditable && page.current_revision.is_draft;
  return (
    <div style={{ height: '100%', maxHeight: '100%', display: 'flex'/**, marginLeft: isMedium ? 0 : 20, paddingTop: 8**/ }}>
      <SnippetWrapper
        style={{
          height: '100%',
          overflow: 'hidden',
          gridTemplateRows: '[actions-start] min-content [editor-start] 1fr [editor-end]',
        }}
      >
        <div style={{
          gridColumnStart: 'main-start',
          gridColumnEnd: 'sidebar-end',
          gridRowStart: 'actions-start',
          gridRowEnd: 'editor-start',
          display: 'flex',
          alignItems: 'center'
        }}>
          <PageToolbar
            site={site}
            page={page}
            onPreview={(revision) => {
              setPreviewState({
                open: true,
                revision,
              });
            }}
            handleName={(name) => updatePageRevision({ name })}
            handleSlug={(slug) => updatePage({ slug })}
            editable={isEditable}
          />
        </div>
        <div style={{ display: 'contents' }}>
          <ErrorBoundary style={{ paddingTop: 30 }}>
            {/*TODO PB connectedEditingBlocked notice*/}
            <SnippetEditor
              key={page.current_revision.id}
              placeholder="Page content..."
              value={{
                id: contentId,
                delta: (page && page.current_revision.delta) || { ops: [] },
              }}
              editable={isEditable}
              owner={'owner' === site.user_permission}
              onChange={async(type, delta, id) => {
                if (type === 'delta') {
                  setContentId(id);
                  await updatePageRevision({ delta });
                }
              }}
              snippetId={page.id}
              groupId={site.id}
              showCommands={showCommands}
              setShowCommands={setShowCommands}
              hideCommandsListToolbar
              editorStyle={{
                marginLeft: 2,
              }}
              draftComponent={showDraftNotice() ? <DraftNotice page={page} readOnly={site.user_permission === 'viewer'}/> : null}
              commandErrorBottom={showDraftNotice() ? 49 : 0}
              onEmbeddedCommandError={(errors) => {
                hasEmbeddedCommandError.current = errors;
              }}
            />
          </ErrorBoundary>
        </div>
      </SnippetWrapper>
      {previewState?.open && <PagePreview
        site={site}
        page={page}
        onClose={() => setPreviewState({ open: false })}
        revision={previewState.revision}
        onRestore={async() => {
          const updatedPage = await restorePageRevision(page.id, previewState.revision);
          if (updatedPage) {
            store.dispatch({
              type: 'UPDATE_PAGE',
              pageId: page.id,
              data: updatedPage,
            });
            setPreviewState({ open: false });
          }
        }}
      />}
    </div>
  );
}
