import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import T from '@mui/material/Typography';
import UserAvatar from '../UserAvatar/UserAvatar';
import { Button, Chip } from '@mui/material';
import { uidToUserInfo } from '../PermissionList/utilities';
import { useIsMounted } from '../../hooks';
import moment from 'moment/moment';



/**
 * @param {object} props
 * @param {PageRevision} props.revision
 * @param {boolean} props.isMostRecent
 * @param {boolean} props.isPublished
 * @param {() => void} props.onPreview
 */
export default function PageRevisionEntry(props) {
  const { revision, isPublished, isMostRecent } = props;
  const isMounted = useIsMounted();
  const [updateByInfo, setUpdateByInfo] = useState(null);
  const entryLabel = isMostRecent ? 'Current version' : 'Prior version';
  const entryChip = isPublished ? 'Published' : (isMostRecent ? 'Draft' : null);

  useEffect(() => {
    // in case the user is deleted
    if (revision.updated_by) {
      uidToUserInfo(revision.updated_by).then(data => {
        if (isMounted.current) {
          setUpdateByInfo(data);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  return <Timeline>
    <TimelineItem>
      <TimelineOppositeContent style={{
        width: 170,
        flex: 'none'
      }}>
        {moment(revision.updated_on).fromNow()}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={(isPublished ? 'primary' : undefined)} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <div style={{ 'display': 'flex', alignItems: 'center' }}>
          <T paragraph style={{ display: 'flex', flexGrow: 1, marginBottom: 0 }}>
            <span style={{ fontWeight: 'bold' }}>{entryLabel}</span>
            {!!entryChip && <Chip label={entryChip} size="small" sx={{ marginLeft: 1 }} color={isPublished ? 'primary' : 'default'}/>}
          </T>
          {!isMostRecent &&
            <Button
              size="small"
              onClick={() => {
                props.onPreview();
              }}
            >Preview
            </Button>}
        </div>
        {!!updateByInfo && <>
          <T variant="subtitle2" paragraph style={{ marginTop: 20 }}>{isPublished ? 'Most recent update by' : 'Last version update made by'}</T>
          <UserAvatar info={updateByInfo}/>
        </>}
      </TimelineContent>
    </TimelineItem>
  </Timeline>;
}
