import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Grid from '@mui/material/Grid';
import { useIsXSmall, useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import { useHistory, useParams } from 'react-router-dom';
import SiteNavigator from './SiteNavigator';
import SitePanel from './SitePanel';
import '../App/inner_main.css';
import { fetchSite, loadSite } from './apis';
import { LinearProgress } from '@mui/material';
import { DatabaseMissing } from '../Database/DatabaseMissing';
import { useTablesSocket } from '../../hooks/useTablesSocket';
import { store } from '@store';
import { toast } from '../../message';


export default function SiteEditor() {
  const isNotLoggedIn = useTypedSelector(store => !store.userState.uid);
  const { id: siteId } = /** @type {{ id: string }} */ (useParams());

  if (isNotLoggedIn) {
    return null;
  }

  return <SiteEditorLoader siteId={siteId} />;
}

/**
 @param {object} props
 @param {string} props.siteId
 */
function SiteEditorLoader(props) {
  const [loading, setLoading] = useState(true);
  const site = useTypedSelectorShallowEquals(store => store.sitesState.site);

  useEffect(() => {
    (async() => {
      try {
        await loadSite(props.siteId);
      } catch {
        // do nothing, the not found component will be displayed
      } finally {
        setLoading(false);
      }
    })();
  }, [props.siteId]);

  if (loading) {
    return <div style={{
      textAlign: 'center'
    }}>
      <LinearProgress />
    </div>;
  }

  if (!site) {
    return <DatabaseMissing itemType="site" />;
  }

  return <SiteEditorInner siteId={props.siteId}/>;
}


/**
 * @typedef {object} SiteCreatedEventData
 * @property {'site_created'} type
 * @property {SiteObjectType} site
 */


/**
 * @typedef {object} SiteUpdatedEventData
 * @property {'site_updated'} type
 * @property {SiteObjectType} site
 */

/**
 * @typedef {object} SiteDeletedEventData
 * @property {'site_deleted'} type
 * @property {string} site_id
 */

/**
 * @typedef {object} SitePermissionsChangedEventData
 * @property {'site_permissions_changed'} type
 * @property {string} site_id
 */

/**
 * @typedef {object} PageCreatedEventData
 * @property {'page_created'} type
 * @property {PageObjectType} page
 */

/**
 * @typedef {object} PageUpdatedEventData
 * @property {'page_updated'} type
 * @property {PageObjectType} page
 */

/**
 * @typedef {object} PageDeletedEventData
 * @property {'page_deleted'} type
 * @property {string} page_id
 */

/**
 * @typedef {object} PageMovedEventData
 * @property {'page_moved'} type
 * @property {'string'} page_id
 * @property {number} new_order
 */

/**
 * @typedef {SiteCreatedEventData |
 * SiteUpdatedEventData |
 * SiteDeletedEventData |
 * SitePermissionsChangedEventData |
 * PageCreatedEventData |
 * PageUpdatedEventData |
 * PageDeletedEventData |
 * PageMovedEventData
 * } PageBlazeUpdateEvent
 */

/**
 * @param {object} props
 * @param {string} props.siteId
 */
function SiteEditorInner(props) {
  const { push: navigate } = useHistory();

  async function showDeleteToastAndWait(message) {
    toast(message, {
      duration: 6000,
      intent: 'danger'
    });
    return new Promise((r) => setTimeout(r, 2000));
  }

  // sync site updates with the store
  useTablesSocket({
    page: 'site',
    site_id: props.siteId,
  }, /** @type {(data: PageBlazeUpdateEvent) => Promise<void>} */ (async(data) => {
    if (data.type === 'site_updated') {
      store.dispatch({
        type: 'UPDATE_SITE',
        data: data.site,
      });
    } else if (data.type === 'site_deleted') {
      await showDeleteToastAndWait('Current site is deleted.');
      navigate('/');
    } else if (data.type === 'site_permissions_changed') {
      // WORKAROUND: in the backend, we send updates via a broadcast, this broadcast is user agnostic,
      // we don't loop over users, we loop over channels, each user in this channel can have a different
      // permission, that's why we cannot get the user permission in a broadcast update.Therefore, we're
      // going to re-fetch the site, to get the updated "user_permission" value.
      const updatedSite = await fetchSite(data.site_id);
      if (updatedSite) {
        store.dispatch({
          type: 'UPDATE_SITE',
          data: updatedSite,
        });
      } else {
        // can happen in case it's a site_unshared event
        await showDeleteToastAndWait('Current site is no longer shared with you.');
        navigate('/');
      }
    } else if (data.type === 'page_created') {
      store.dispatch({
        type: 'CREATE_PAGE',
        data: data.page,
      });
      store.dispatch({ type: 'SORT_PAGES' });
    } else if (data.type === 'page_updated') {
      store.dispatch({
        type: 'UPDATE_PAGE',
        pageId: data.page.id,
        data: data.page,
      });
    } else if (data.type === 'page_deleted') {
      const { id: selectionId } = store.getState().sitesState.selection;
      if (selectionId === data.page_id) {
        await showDeleteToastAndWait('Current page is deleted.');
        await navigate(`/site/${store.getState().sitesState.site.id}`);
      }
      store.dispatch({
        type: 'DELETE_PAGE',
        pageId: data.page_id,
      });
    } else if (data.type === 'page_moved') {
      store.dispatch({
        type: 'UPDATE_PAGE',
        pageId: data.page_id,
        data: { order: data.new_order },
      });
      store.dispatch({ type: 'SORT_PAGES' });
    }
  }));

  return <>
    {/* TODO PB <SiteNavigatorDrawer />*/}
    <div className="container-fluid page-body">
      <Grid container style={{ height: '100%' }}>
        <SiteNavigatorGrid />
        <Grid item xs={12} sm={8} md={9} style={{ height: '100%' }}>
          <ErrorBoundary>
            <SitePanel />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </div>
  </>;
}



function SiteNavigatorGrid() {
  let isXSmall = useIsXSmall();

  if (isXSmall) {
    // TODO PB hamburger menu
    return null;
  }

  return <Grid
    item
    sm={4}
    md={3}
    sx={{
      height: '100%'
    }}
  >
    <div style={{
      paddingLeft: 15,
      height: '100%',
      backgroundColor: '#fdfbfb'
    }}>
      <ErrorBoundary>
        <SiteNavigator />
      </ErrorBoundary>
    </div>
  </Grid>;
}
