import React from 'react';
import { publishPageRevision } from './apis';
import AsyncButton from '../AsyncButton/AsyncButton';
import Alert from '@mui/material/Alert';


/**
 * @param {object} props
 * @param {PageObjectType} props.page
 * @param {boolean} props.readOnly
 */
export default function DraftNotice(props) {
  return <Alert
    severity="info"
    action={!props.readOnly ?
      (
        <AsyncButton
          size="small"
          variant="text"
          sx={{
            marginLeft: 1,
          }}
          onClick={async(done) => {
            await publishPageRevision(props.page.id);
            done();
          }}
        >Publish</AsyncButton>
      ) : null}
  >
    This is a draft version.
  </Alert>;
}
