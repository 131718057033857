import { useTypedSelectorShallowEquals } from '../hooks';


/**
 * The hook is used to merge a state object with a nested special attribute "_uncommitted",
 * the "_uncommitted" attribute is used to store changes made by the user, but not successfully
 * submitted to the backend yet. Moreover, it makes it easy to revert back to the original
 * store state by nullifying it, this way we make sure the store is always populated from
 * the backend data only.
 * @template T
 * @param {(store: any) => (T & import('../components/PageBlaze/store').UncommittedChangesObject)|null} storeFn
 * @returns {T|null}
 */
export default function useUncommittedStore(storeFn) {
  return /** @type {T|null} */ (useTypedSelectorShallowEquals(store => {
    let storeEntity = storeFn(store);
    if (!storeEntity) {
      return storeEntity;
    }

    if (storeEntity._uncommitted) {
      storeEntity = Object.assign({}, storeEntity);
      const uncommitted = Object.assign({}, storeEntity._uncommitted);
      delete storeEntity._uncommitted;
      return Object.assign(storeEntity, uncommitted);
    }
    return Object.assign({}, storeEntity);
  }));
}
