import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './css/index.css';
import './css/blueprint_lpt3.css';
import { Provider } from 'react-redux';
import { EmptyState } from './js/components/EmptyState/EmptyState';
import StandalonePage from './js/components/PageBlaze/StandalonePage';
import { AUTH_URL_PREFIX, PAGE_ENGINE_PRIVATE_DOMAIN_LOCAL, TABLES_BACKEND_DOMAIN } from './js/flags';
import { LinearProgress } from '@mui/material';
import { useTypedSelector } from './js/hooks';
import { pbeStore } from './js/components/PageBlaze/pbe_store';
import useSession from './js/hooks/useSession';
import { getAuth, getIdToken } from 'firebase/auth';
import Button from '@mui/material/Button';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from './js/components/ErrorBoundary/ErrorBoundary';
import SiteIconBasic from '@mui/icons-material/Web';
import { BrowserRouter, Route, Switch, useParams } from 'react-router-dom';
import NotFound from './js/components/NotFound/NotFound';

const MAX_PAGE_CACHE_LENGTH = 10;

const pageTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
});


/**
 * @typedef {object} PbEnginePageProps
 * @property {boolean=} landing
 * @property {boolean=} notFound
 * @property {string=} siteSlug
 * @property {string=} pageSlug
 * @property {boolean=} useAuth
 * @property {string=} urlPrefix
 */


/**
 * @param {PbEnginePageProps} props
 */
function StandalonePageLoader(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(/** @type {import('./js/components/PageBlaze/StandalonePage').PageData} */ null);
  const [error, setError] = useState(false);
  const pageCache = useRef(/** @type {Map<string, {lastAccessed: number, data: import('./js/components/PageBlaze/StandalonePage').PageData}>} **/(new Map()));
  const userState = useTypedSelector(store => store.userState);

  /**
   * @param {string} siteSlug
   * @param {string} pageSlug
   * @return {Promise<import('./js/components/PageBlaze/StandalonePage').PageData>}
   */
  async function fetchPageData(siteSlug, pageSlug) {
    let apiBase;
    if (window.location.host.startsWith('localhost')) {
      // on localhost, we either use PBE local/production according to the tables backend endpoint
      if (TABLES_BACKEND_DOMAIN.includes('localhost')) {
        // data is located on localhost, local PBE should be use
        apiBase = PAGE_ENGINE_PRIVATE_DOMAIN_LOCAL + '/';
      } else {
        // data is located on spark or production, only production is accessible from localhost
        // note that the spark URL in the next line is using prod database
        apiBase = 'https://pbe-stage-g4wcehtwdq-uc.a.run.app/';
        if (TABLES_BACKEND_DOMAIN.includes('spark')) {
          console.warn('Data is hosted on spark, not accessible from localhost');
        }
      }
    } else {
      apiBase = props.urlPrefix;
    }
    const response = await fetch(`${apiBase}api/page/${siteSlug}/${pageSlug || ''}`, {
      method: 'GET',
      headers: !!props.useAuth ? {
        Authorization: `FBBEARER ${await getIdToken(getAuth().currentUser)}`,
      } : undefined,
    });
    if (response.status !== 200) {
      throw new Error();
    }

    const responseJson = await response.json();
    if (responseJson.status !== 'success') {
      throw new Error();
    }

    return responseJson.data;
  }

  /**
   * Get page data if exists in the cache, or call the endpoint and cache it.
   * @param {string} siteSlug
   * @param {string} pageSlug
   * @return {Promise<import('./js/components/PageBlaze/StandalonePage').PageData>}
   */
  async function getPageData(siteSlug, pageSlug) {
    const cacheKey = `${siteSlug}/${pageSlug}`;
    const cache = pageCache.current;
    let pageData;
    if (window['initialData']) {
      pageData = window['initialData'];
      delete window['initialData'];
    } else if (cache.has(cacheKey)) {
      pageData = cache.get(cacheKey).data;
    } else {
      pageData = await fetchPageData(siteSlug, pageSlug);
      if (cache.size === MAX_PAGE_CACHE_LENGTH) {
        let cachedEntryToDelete;
        for (const cachedEntry of cache.values()) {
          if (!cachedEntryToDelete || cachedEntry.lastAccessed < cachedEntryToDelete.lastAccessed) {
            cachedEntryToDelete = cachedEntry;
          }
        }
        cache.delete(`${cachedEntryToDelete.data.siteSlug}/${cachedEntryToDelete.data.pageSlug}`);
      }
    }

    cache.set(cacheKey, {
      lastAccessed: Date.now(),
      data: pageData,
    });
    return pageData;
  }

  useEffect(() => {
    const initialError = window['initialData']?.error;
    if (!!initialError) {
      delete window['initialData'];
      // if the user is using the private URL (/p/SITE_SLUG/PAGE_SLUG), and no cookie was provided,
      // or the cookie is expired, then we should retry to load that page once again using the
      // firebase session token, if available
      if (initialError !== 'UNAUTHENTICATED' || !props.useAuth) {
        setError(true);
        setLoading(false);
        return;
      }
    }

    (async() => {
      try {
        setLoading(true);
        const pageData = await getPageData(props.siteSlug, props.pageSlug);
        setData(pageData);
        document.title = `${pageData.pageTitle} | ${pageData.siteTitle}`;
        setError(false);
      } catch {
        document.title = 'Not Found | Page Blaze';
        setError(true);
      } finally {
        setLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.siteSlug, props.pageSlug]);

  if (!data && loading) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (error) {
    return <MissingPage />;
  }

  return <StandalonePage
    loading={loading}
    currentPageSlug={props.pageSlug || '/'}
    data={data}
    urlPrefix={props.urlPrefix}
    userState={userState}
  />;
}


function StandalonePageSessionLoader() {
  const sessionLoading = useSession();

  if (sessionLoading) {
    return <LinearProgress variant="indeterminate" />;
  }

  return <EmptyState
    icon="ERROR"
    title="You are not logged in."
    description={<>
      Please login before you can access this site. <br/>
      <Button sx={{ mt: 2 }} variant="contained" href="/">Click here to login</Button>
    </>}
  />;
}

/**
 * @param {PbEnginePageProps} props
 */
function StandalonePageLoaderWithAuth(props) {
  const userState = useTypedSelector(store => store.userState);
  if (!userState?.isLoaded) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (!userState?.uid) {
    // this will either show the not logged in message or create a token from an existing session
    return <StandalonePageSessionLoader />;
  }

  return <StandalonePageLoader {...props} />;
}

function MissingPage() {
  const userEmail = useTypedSelector(store => store.userState?.email);
  return <EmptyState
    icon="MISSING"
    title="Could not load page"
    description={<>That page does not exist or you do not have access to it. {!!userEmail && <><br /><br />You are logged in as {userEmail}</>}</>}
  />;
}

function LandingPage() {
  return <EmptyState
    icon={SiteIconBasic}
    title="Want to host your own interactive pages?"
    description={<>Visit <a href="https://page.blaze.today">Page Blaze</a> and create your first site now!</>}
  />;
}

function PageComponent() {
  const {
    siteSlug,
    pageSlug,
  } = /** @type {{ siteSlug: string, pageSlug?: string }} */ (useParams());
  const useAuth = document.location.pathname.startsWith(AUTH_URL_PREFIX)
    // even if the URL starts with /p in [spark.]pageblaze.com, auth should not be used
    && !document.location.hostname.includes('pageblaze.com');
  let urlPrefix;
  if (useAuth) {
    urlPrefix = AUTH_URL_PREFIX;
  } else {
    urlPrefix = '/';
  }

  const props = {
    useAuth,
    siteSlug,
    pageSlug,
    urlPrefix,
  };

  if (useAuth) {
    return <StandalonePageLoaderWithAuth {...props} />;
  }

  return <StandalonePageLoader {...props} />;
}

export function PageEngineApp() {
  return <StyledEngineProvider injectFirst>
    <ThemeProvider theme={pageTheme}>
      <CssBaseline />
      <Provider store={pbeStore}>
        <BrowserRouter>
          <Switch>
            <Route
              exact path={['/p/', '/']}
              render={() => <ErrorBoundary>
                <LandingPage />
              </ErrorBoundary>}
            />
            <Route
              exact path={[
                '/p/:siteSlug/:pageSlug/',
                '/p/:siteSlug/',
                '/:siteSlug/:pageSlug/',
                '/:siteSlug/',
              ]}
              render={() => <ErrorBoundary>
                <PageComponent />
              </ErrorBoundary>}
            />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>;

}

if (import.meta.env.VITE_APP_APP_TYPE !== 'PAGE') {
  // runs inside the Page Rendering engine, include in ../pages/src/templates/page.html
  window.onload = function() {
    const root = createRoot(document.getElementById('form'));
    root.render(<PageEngineApp />);
  };
}
