import { useEffect, useRef } from 'react';
import { doSafeTableRequest, errorDetailToString } from './useTables';
import useCloseWarning from '../hooks/useCloseWarning';
import { storage } from '../utilities';
import { store } from '@store';
import { toast } from '../message';


/**
 * @template T
 * @typedef {object} DebouncedUpdateConfig
 * @property {string} endpointUrl
 * @property {(store: any) => T|null} storeFn
 * @property {Record<string, any> & {type: string}} dispatchObjectBase
 */

/**
 * @template T
 * @typedef {object} DebouncedUpdateRequest
 * @property {DebouncedUpdateConfig<T>=} config
 * @property {Partial<T>=} data
 * @property {Promise<any>=} promise
 * @property {function=} resolve
 * @property {function=} reject
 * @property {function=} notifyWillWriteFn
 * @property {number=} submitTimer
 */

/**
 * @template T
 * @param {DebouncedUpdateConfig<T>} config
 * @returns {(data: Partial<T>, debounce?: number) => Promise<any>}
 */
export default function useDebouncedUpdate(config) {
  const pendingRequest = useRef(/** @type {DebouncedUpdateRequest<T>} */ ({ config }));
  const {
    warnOnExit,
    cancelWarningOnClose,
  } = useCloseWarning();

  /**
   * Update the store, then call the update endpoint, on failure, undo store changes. In addition to that,
   * warn user on exit before committing changes to the backend.
   * @param {Partial<T>} updateData the data used to update
   * @param {number} debounce number of milliseconds before calling the endpoint
   * @returns {Promise<any>}
   */
  function updateFn(updateData, debounce = 1000) {
    if (!pendingRequest.current.promise) {
      pendingRequest.current.promise = new Promise((resolve, reject) => {
        pendingRequest.current.resolve = resolve;
        pendingRequest.current.reject = reject;
      });
    }

    // update the store immediately with the changes, but in uncommitted mode
    store.dispatch({ ...pendingRequest.current.config.dispatchObjectBase, data: updateData, uncommitted: true });
    // add the most recent update data to the pending object
    pendingRequest.current.data = Object.assign(pendingRequest.current.data || {}, updateData);
    // warn on exit, and notify pending updates
    warnOnExit('There are pending changes to be saved. Are you sure you want to leave now?');
    if (!pendingRequest.current.notifyWillWriteFn) {
      pendingRequest.current.notifyWillWriteFn = storage.notifyWillWrite();
    }
    // debounce the call to the backend
    clearTimeout(pendingRequest.current.submitTimer);
    pendingRequest.current.submitTimer = window.setTimeout(async() => {
      // remember/change the current pending request immediately before executing the async operations,
      // otherwise, overlapping callbacks can lead to wrong state
      const currentPendingRequest = pendingRequest.current;
      pendingRequest.current = { config: pendingRequest.current.config };
      await submitPendingRequest(currentPendingRequest);
    }, debounce);

    return pendingRequest.current.promise;
  }

  /**
   * @param {DebouncedUpdateRequest<T>} pendingRequest
   * @returns {Promise<void>}
   */
  async function submitPendingRequest(pendingRequest) {
    const { config, data, resolve, reject, notifyWillWriteFn } = pendingRequest;
    let errorMessage;
    let responseData;
    try {
      responseData = await doSafeTableRequest(config.endpointUrl, 'PATCH', data);
      if (responseData?.error) {
        errorMessage = `An issue occurred while saving: ${errorDetailToString(responseData.detail)}`;
        reject(responseData);
      } else {
        resolve(responseData);
      }
    } catch (error) {
      errorMessage = 'An issue occurred while saving. If this issue persists, please report it to <support@blaze.today>.';
      reject(null);
    }

    if (errorMessage) {
      // report the error to the user
      toast(errorMessage, {
        duration: 6000,
        intent: 'danger'
      });
    } else {
      // commit the store changes
      store.dispatch({ ...config.dispatchObjectBase, data });
    }

    // cleanup
    store.dispatch({ ...config.dispatchObjectBase, data: { _uncommitted: null } });
    cancelWarningOnClose();
    notifyWillWriteFn();
  }

  useEffect(() => {
    pendingRequest.current = { config };
    return () => {
      clearTimeout(pendingRequest.current.submitTimer);
      if (pendingRequest.current.data) {
        (async(previousPendingRequest) => await submitPendingRequest(previousPendingRequest))(pendingRequest.current);
      }
    };
    // eslint-disable-next-line
  }, [JSON.stringify(config)]);

  return updateFn;
};
