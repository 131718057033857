import React, { useState, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { Box, Button, Divider, MenuItem } from '@mui/material';
import T from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import BufferedEditableText from '../BufferedEditableText/BufferedEditableText';
import useUncommittedStore from '../../hooks/useUncommittedStore';
import { toast } from '../../message';
import EmojiSelector from '../Messaging/LazyEmojiSelector';
import SiteIconBasic from '@mui/icons-material/Web';
import ReadonlyIcon from '@mui/icons-material/RemoveRedEye';
import PublishIcon from '@mui/icons-material/Public';
import UnpublishIcon from '@mui/icons-material/PublicOff';
import RestoreFromTrashOutlined from '@mui/icons-material/RestoreFromTrashOutlined';
import SideBarButton from '../Group/SideBarButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAddAlt';
import { Link, useHistory } from 'react-router-dom';
import useDebouncedUpdate from '../../hooks/useDebouncedUpdate';
import { deleteSite, getSiteUrl, publishSite, unpublishSite, updateSite as updateSiteFn } from './apis';
import CircularProgress from '@mui/material/CircularProgress';
import { store } from '@store';
import SlugEditor from './SlugEditor';
import validateSlug, { DEFAULT_THEME_COLOR, getSiteBaseUrl } from './utils';
import TrashedPagesDialog from './TrashedPagesDialog';
import ConnectedSiteButton from './ConnectedSiteButton';
import ColorPicker from '../SnippetEditor/Table/ColorPicker';


const COLORS = [
  ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff'],
  ['#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff'],
  ['#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff'],
  ['#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2'],
  ['#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466']
];


export default function Site() {
  const { push: navigate } = useHistory();
  const site = useUncommittedStore(store => store.sitesState.site);
  const [showEmoji, setShowEmoji] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const emojiButtonRef = useRef(null);

  const debouncedSiteUpdate = useDebouncedUpdate({
    storeFn: store => store.sitesState.site,
    endpointUrl: getSiteUrl(site.id),
    dispatchObjectBase: {
      type: 'UPDATE_SITE',
    },
  });

  /**
   * @param {Partial<Site>} data
   */
  async function updateSite(data) {
    try {
      await debouncedSiteUpdate(data, 0);
    } catch {
      // TODO PB logs
    }
  }

  if (!site) {
    return;
  }

  const isOwner = 'owner' === site.user_permission;
  const isEditor = ['owner', 'editor'].includes(site.user_permission);
  const editable = isEditor; // TODO PB connected editing blocked

  /**
   * @param {boolean} publish
   * @returns {Promise<void>}
   */
  async function publishUnpublishSite(publish) {
    setIsPublishing(true);
    try {
      let response;
      if (publish) {
        response = await publishSite(site.id);
      } else {
        response = await unpublishSite(site.id);
      }
      if (response) {
        store.dispatch({
          type: 'UPDATE_SITE',
          data: { is_published: publish },
        });
      }
    } finally {
      setIsPublishing(false);
    }
  }

  return <Paper elevation={3} style={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
    <Box
      sx={{
        display: {
          md: 'flex',
          xs: 'flex'
        },
        flexDirection: {
          md: 'row',
          xs: 'column'
        },
        minHeight: '100%'
      }}>
      <div style={{
        position: 'relative',
        overflow: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundAttachment: 'fixed',
        backgroundSize: 'contain',
        padding: 30,
        paddingBottom: 15,
        paddingTop: 27,
        display: 'flex',
        flexDirection: 'column',
        flex: 'min-content'
      }}>
        <T variant="h3" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <IconButton
            ref={emojiButtonRef}
            sx={{
              mr: 2
            }}
            onClick={() => {
              setShowEmoji(!showEmoji);
            }}
            disabled={!editable}
          >
            <Tooltip title="Site icon">
              {!!site.icon ? <div
                translate="no"
                style={{
                  fontSize: '34px',
                  lineHeight: '34px',
                  width: 38,
                  height: 38,
                  color: 'initial',
                  verticalAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>{site.icon}</div> : <SiteIconBasic fontSize="large"/>}
            </Tooltip>
          </IconButton>
          <BufferedEditableText
            disabled={!editable}
            placeholder="Edit site name..."
            selectAllOnFocus
            minWidth={280}
            value={site.name}
            onChange={(name) => updateSite({ name })}
            style={{
              overflow: 'hidden'
            }}
            validate={(value) => {
              if (value.trim() === '') {
                toast('The site name cannot be blank.', {
                  duration: 4000,
                  intent: 'danger'
                });
                return false;
              }
              return true;
            }}
          />
        </T>
        <br/>
        <SlugEditor
          value={site.slug}
          baseUrl={`${getSiteBaseUrl(site)}/`}
          path={`${site.slug}/`}
          placeholder="slug…"
          style={{
            height: 38,
            width: '100%',
            marginBottom: 14,
          }}
          onChange={(slug) => updateSite({ slug })}
          validate={validateSlug}
          readOnly={!editable}
        />
        <BufferedEditableText
          disabled={!editable}
          maxLines={12}
          minLines={4}
          multiline
          placeholder={editable ? 'Edit description...' : ''}
          selectAllOnFocus={false}
          value={site.description}
          onChange={(description) => updateSite({ description })}
        />
        {editable && <div style={{ marginTop: 8 }}>
          <T variant="h6" sx={{ marginBottom: 1 }} paragraph>Theme options</T>
          <div style={{ display: 'flex', alignItems: 'center', height: 36 }}>
            <T variant="body1" sx={{ mr: 1 }}>Theme color</T>
            <ColorPicker
              value={site.theme_overrides?.themeColor || DEFAULT_THEME_COLOR}
              onChange={(themeColor) => updateSite({ theme_overrides: Object.assign({}, site.theme_overrides, { themeColor }) })}
              colors={COLORS}
            />
            {(!site.theme_overrides?.themeColor || site.theme_overrides.themeColor !== DEFAULT_THEME_COLOR) && <Button
              onClick={() => updateSite({ theme_overrides: Object.assign({}, site.theme_overrides, { themeColor: DEFAULT_THEME_COLOR }) })}
            >Reset</Button>}
          </div>
        </div>}
        <div style={{ flex: 1 }}></div>
      </div>
      <Paper
        elevation={1}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'grey.50',
          flex: {
            md: '0 0 315px',
            xs: 'auto'
          },
          paddingBottom: 1.875,
          paddingTop: 3.4,
          overflow: 'auto'
        }}
      >
        <Box
          sx={{
            flex: 1,
            px: 4.5,
          }}
        >
          {isOwner && <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={() => navigate(`/site/${site.id}/sharing`)}
          >
            Share site
          </Button>}
          {(!isEditor) ?
            <div style={{ opacity: 0.7, marginTop: 12 }}><ReadonlyIcon style={{ verticalAlign: 'middle' }} /> <span>
                  You are a viewer of this {site.is_published ? 'published ' : ''} shared site and cannot edit it
            </span></div>
            : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            px: 2
          }}
        >
          <div style={{ padding: 16 }}>
            <T variant="h6" paragraph>Site options</T>
            {isEditor && <ConnectedSiteButton
              isOwner={isOwner}
              shareBlocksConnect={/** TODO PB */ false}
              groupUpdateFn={/** @type {(data: ConnectedSettingsType) => Promise<void>} */ async(data) => {
                const updatedSite = await updateSiteFn(site.id, data);
                if (updatedSite) {
                  store.dispatch({
                    type: 'UPDATE_SITE',
                    data: { connected: updatedSite.connected },
                  });
                }
              }}
            />}
          </div>
          {!isOwner ? null : site.is_published ?
            <Tooltip
              title="Unpublish site"
              placement="left"
            >
              <SideBarButton
                disabled={isPublishing}
                startIcon={isPublishing ?
                  <CircularProgress color="inherit" size={16} style={{ marginLeft: 2, marginRight: 2 }}/> :
                  <UnpublishIcon />}
                onClick={async () => await publishUnpublishSite(false)}
              >
                Unpublish site
              </SideBarButton>
            </Tooltip> :
            <Tooltip
              title="Publish site"
              placement="left"
            >
              <SideBarButton
                disabled={isPublishing}
                startIcon={isPublishing ?
                  <CircularProgress color="inherit" size={16} style={{ marginLeft: 2, marginRight: 2 }}/> :
                  <PublishIcon />}
                onClick={async () => await publishUnpublishSite(true)}
              >
                Publish site
              </SideBarButton>
            </Tooltip>
          }
          {editable && <Tooltip
            title="Trashed pages"
            placement="left"
          >
            <MenuItem
              component={Link}
              to="#site-trash"
              sx={{
                padding: 0,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'revert',
                }
              }}
            >
              <SideBarButton
                startIcon={<RestoreFromTrashOutlined />}
                sx={{
                  flexGrow: 1,
                }}
              >
                Trashed pages…
              </SideBarButton>
            </MenuItem>

          </Tooltip>}
          {isOwner && <>
            <Divider />
            <Tooltip
              title={<span>Delete the site <b>{site.name}</b> and its pages</span>}
              placement="left"
            >
              <SideBarButton
                startIcon={<DeleteIcon style={{ fontSize: 24 }} />}
                onClick={async() => {
                  const isDelete = await deleteSite(site, 'site_menu');
                  if (isDelete) {
                    navigate('/');
                  }
                }}>
                Delete site…
              </SideBarButton>
            </Tooltip>
          </>}
        </Box>
      </Paper>
    </Box>
    {showEmoji && <EmojiSelector
      target={emojiButtonRef.current}
      showRemove={!!site.icon}
      removeLabel="Remove icon"
      onClose={() => {
        setShowEmoji(false);
      }}
      onSelect={async(icon) => {
        setShowEmoji(false);
        await updateSite({ icon: icon || '' });
      }}
    />}
    <TrashedPagesDialog
      siteId={site.id}
    />
  </Paper>;
}
