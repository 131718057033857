import React, { useEffect, useRef, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton, Tooltip } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { toast } from '../../message';
import { useIsMounted } from '../../hooks';


const DELAYED_UPDATE_TIMEOUT = 1000;


/**
 * @param {object} props
 * @param {string} props.value
 * @param {string} props.placeholder
 * @param {string} props.baseUrl
 * @param {string} props.path
 * @param {(slug: string) => void} props.onChange
 * @param {React.CSSProperties=} props.style
 * @param {(text: string) => boolean} props.validate
 * @param {boolean} props.readOnly
 */
export default function SlugEditor(props) {
  const isMounted = useIsMounted();
  const { value, placeholder, onChange, style, baseUrl, path } = props;
  const [updatedSlug, setUpdatedSlug] = useState(/** @type {string} */null);
  const lastValidatedValue =  useRef(/** @type {string} */null);
  const slugChangeTimeout =  useRef(/** @type {number} */null);
  const url = baseUrl + path;
  /**
   * @param {string} newSlug
   * @param {boolean} blurred
   */
  function handleSlugChange(newSlug, blurred) {
    if (newSlug === null) {
      return;
    }

    // cancel the pending timeout, if existing
    clearSlugChangeTimeout();
    // map the new value to the input component
    setUpdatedSlug(newSlug);

    if (blurred) {
      // do it immediately
      validateAndSet(newSlug);
      // the user has left the input, we can now revert-back the value if it was not valid
      setUpdatedSlug(null);
      lastValidatedValue.current = null;
    } else {
      slugChangeTimeout.current = setTimeout(()=> {
        if (!isMounted.current) {
          return;
        }

        validateAndSet(newSlug);
        slugChangeTimeout.current = null;
      }, DELAYED_UPDATE_TIMEOUT);
    }
  }

  function validateAndSet(newSlug) {
    if (lastValidatedValue.current === newSlug) {
      // avoid duplicate validations when leaving the input
      return;
    }

    if (props.validate(newSlug)) {
      onChange(newSlug);
      setUpdatedSlug(null);
    }

    lastValidatedValue.current = newSlug;
  }

  function clearSlugChangeTimeout() {
    if (slugChangeTimeout.current) {
      clearTimeout(slugChangeTimeout.current);
      slugChangeTimeout.current = null;
    }
  }

  useEffect(() => {
    return clearSlugChangeTimeout;
  }, []);

  return <OutlinedInput
    size="small"
    style={{
      height: '80%',
      borderRadius: 14,
      ...style,
    }}
    placeholder={placeholder}
    onBlur={() => handleSlugChange(updatedSlug, true)}
    value={updatedSlug === null ? value : updatedSlug}
    onChange={(e) => handleSlugChange(e.target.value.toLowerCase(), false)}
    readOnly={props.readOnly}
    type="text"
    sx={{
      fontSize: 14,
      paddingLeft: 0,
      backgroundColor: '#f7f8f8',
    }}
    startAdornment={
      <InputAdornment
        position="end"
        sx={{
          marginLeft: 2,
        }}
      >
        <Tooltip title="Copy URL link">
          <IconButton
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(url);
                toast('Copied to clipboard.', {
                  intent: 'success',
                  duration: 3000,
                });
              } catch {
                toast('Could not copy to clipboard.', {
                  intent: 'danger',
                  duration: 6000,
                });
              }
            }}
            size="small"
          >
            <CopyIcon sx={{ opacity: 0.8 }} />
          </IconButton>
        </Tooltip>
        <a
          className="page-url-link"
          href={url}
          target="_blank" rel="noreferrer"
        >{baseUrl}</a>
      </InputAdornment>
    }
  />;
}
