import React from 'react';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { SearchBase } from '../Search/Search';
import { pageText, searchPages } from './search_utils';
import { useHistory } from 'react-router-dom';


function SiteSearchBase(props) {
  const { push: navigate } = useHistory();
  const {
    allPages,
    site,
  } = useTypedSelectorShallowEquals(store => ({
    site: store.sitesState.site,
    allPages: store.sitesState.site.pages,
  }));

  return <SearchBase
    allSnippets={allPages}
    groups={{ [site.id]: site }}
    priorities={[]}
    searchItemsFn={searchPages}
    itemTextFn={pageText}
    selectFn={(snippetId) => navigate(`/site/${site.id}/page/${snippetId}/`)}
    placeholder="Search page name, slug or contents"
    placeholderSmall="Search for pages..."
    {...props}
  />;
}


const SiteSearch = React.memo(SiteSearchBase);
export default SiteSearch;