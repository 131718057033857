import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { deleteSite, publishSite, unpublishSite } from './apis';
import { ListItemIcon, ListItemText } from '@mui/material';
import PublishIcon from '@mui/icons-material/Public';
import UnpublishIcon from '@mui/icons-material/PublicOff';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DatabaseListCommonMenu from '../Database/DatabaseListCommonMenu';
import { PAGE_API_PREFIX } from '../../flags';
import DatabaseShareDialog from '../Database/DatabaseShareDialog';
import SiteSharedIcon from '@mui/icons-material/FolderShared';
import { useHistory } from 'react-router-dom';


const iconStyle = {
  opacity: 0.8,
  marginRight: 14
};


/**
 * @typedef {object} SiteMenuProps
 * @property {SiteObjectType} site
 * @property {() => Promise<any>=} refetch
 * @property {boolean} inDatabase
 * @property {Element=} menuAnchor
 * @property {() => void} onClose
 * @property {Partial<import('@mui/material').MenuProps>=} menuProps
 * @property {boolean=} props.open
 * @property {React.Ref<HTMLDivElement>=} ref
 * @property {(name: string) => void=} props.onRenamed
 * @property {(published: boolean) => void=} props.onPublished
 * @property {() => void=} props.onDeleted
 * @property {{top: number, left: number}=} props.menuAnchorPosition
 */

/**
 * @type {React.FC<SiteMenuProps>}
 */
const SiteMenu = React.forwardRef((props, ref) => {
  const { push: navigate } = useHistory();
  const [showShare, setShowShare] = useState(false);
  const { site } = props;
  const isOwner = 'owner' === site.user_permission;
  return <>
    {(props.open || !!props.menuAnchor || !!props.menuAnchorPosition) && <Menu
      ref={ref}
      anchorEl={props.menuAnchor}
      anchorPosition={props.menuAnchorPosition}
      anchorReference={props.menuAnchor
        ? 'anchorEl'
        : props.menuAnchorPosition
          ? 'anchorPosition'
          : 'none'}
      open
      onClose={() => {
        props.onClose();
      }}
      onContextMenu={(evt) => {
        evt.preventDefault();
        props.onClose();
      }}
      {...props.menuProps}
    >
      <DatabaseListCommonMenu
        group={props.site}
        inDatabase={props.inDatabase}
        favoritesOptionKey="sites"
        onClose={props.onClose}
        renameEndpoint={`${PAGE_API_PREFIX}/sites/${props.site.id}/`}
        itemName="Site"
        refetch={props.refetch}
        onRenamed={props.onRenamed}
      />
      {isOwner && <MenuItem
        onClick={() => {
          props.onClose();
          if (props.inDatabase) {
            navigate(`/site/${site.id}/sharing`);
          } else {
            setShowShare(true);
          }
        }}
      >
        <ListItemIcon>
          <SiteSharedIcon style={iconStyle} />
        </ListItemIcon>
        <ListItemText primary="Share site…" />
      </MenuItem>}
      {site.is_published ?
        <MenuItem
          disabled={!isOwner}
          onClick={async() => {
            props.onClose();
            await unpublishSite(site.id);
            if (props.refetch) {
              await props.refetch();
            }
            if (props.onPublished) {
              props.onPublished(false);
            }
          }}
        >
          <ListItemIcon>
            <UnpublishIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Unpublish" />
        </MenuItem> :
        <MenuItem
          disabled={!isOwner}
          onClick={async() => {
            props.onClose();
            await publishSite(site.id);
            if (props.refetch) {
              await props.refetch();
            }
            if (props.onPublished) {
              props.onPublished(true);
            }
          }}
        >
          <ListItemIcon>
            <PublishIcon style={iconStyle} />
          </ListItemIcon>
          <ListItemText primary="Publish site" />
        </MenuItem>
      }
      <Divider />
      <MenuItem
        disabled={!isOwner}
        onClick={async() => {
          props.onClose();
          const isDeleted = await deleteSite(site, 'context_menu');
          if (isDeleted) {
            if (props.refetch) {
              await props.refetch();
            }
            if (props.onDeleted) {
              props.onDeleted();
            }
          }
        }}
      >
        <ListItemIcon>
          <DeleteIcon style={iconStyle} />
        </ListItemIcon>
        <ListItemText primary="Delete site…" />
      </MenuItem>
    </Menu>}
    {showShare && <DatabaseShareDialog
      database={site}
      refetch={props.refetch}
      onClose={() => setShowShare(false)}
      entityType="site"
    />}
  </>;
});

export default SiteMenu;
