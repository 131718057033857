import React, { useEffect, useRef, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { getPageRevisions } from './apis';
import T from '@mui/material/Typography';
import PageRevisionEntry from './PageRevisionEntry';
import { EmptyState } from '../EmptyState/EmptyState';
import { useIsMounted } from '../../hooks';

/**
 * @param {object} props
 * @param {PageObjectType} props.page
 * @param {()=>void} props.closeSidebarFn
 * @param {(revision: PageRevision) => void} props.onPreview
 */
export default function PageRevisionsSidebar(props) {
  const { page } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [revisions, setRevisions] = useState(/** @type {PageRevision[]} */ null);
  const [publishedRevisionId, setPublishedRevisionId] = useState(/** @type {string} */ null);
  const requestCounter = useRef(0);
  const isMounted = useIsMounted();

  async function loadPageRevisions() {
    requestCounter.current++;
    const currentRequestCounter = requestCounter.current;
    setLoading(true);
    setError(false);
    try {
      const pageRevisions = await getPageRevisions(page.id);
      if (requestCounter.current !== currentRequestCounter || !isMounted.current) {
        return;
      }

      if (!pageRevisions) {
        setError(true);
      }

      setRevisions(pageRevisions);
      const publishedRevision = pageRevisions.find(r => !r.is_draft);
      if (publishedRevision) {
        setPublishedRevisionId(publishedRevision.id);
      }
    } finally {
      if (requestCounter.current === currentRequestCounter && isMounted.current) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (!props.page) {
      return;
    }

    loadPageRevisions();
    // eslint-disable-next-line
  }, [props.page?.current_revision.id]);

  if (loading) {
    return  <LinearProgress variant="indeterminate" />;
  }

  if (error) {
    return <EmptyState
      icon="ERROR"
      title="An error getting page revisions."
      style={{
        marginTop: 60
      }}
    />;
  }

  return <div style={{ overflow: 'auto' }}>
    <T variant="h5" component="div" paragraph style={{
      marginLeft: 24,
      marginTop: 16
    }}>Page revisions history</T>
    {revisions.map((revision, index) => (
      <PageRevisionEntry
        key={index}
        revision={revision}
        isMostRecent={index === 0}
        isPublished={revision.id === publishedRevisionId}
        onPreview={() => props.onPreview(revision)}
      />
    ))}
  </div>;
}
