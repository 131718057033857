import { useTypedSelector } from '../../hooks';
import { getConnectedConfigOptions, isOrgOwner, orgPref } from '../../flags';
import React from 'react';
import { ChangesRequiredBase } from '../SnippetEditor/SnippetChangesRequired';
import useUncommittedStore from '../../hooks/useUncommittedStore';
import { updateSite } from './apis';
import { store } from '@store';
import ConnectedSettingsContext from '../Group/ConnectedSettingsContext';
import { connectedSiteContextValue } from './utils';


/**
 * @param {Object} props
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {boolean} props.owner
 * @returns
 */
export default function PageChangesRequired(props) {
  const site = useUncommittedStore(store => store.sitesState.site);
  const page = useUncommittedStore(store => store.sitesState.pagesMap[props.snippetId]);
  const { connected, orgLimitsConnectedEdit } = useTypedSelector((store) => {
    return {
      connected: site && getConnectedConfigOptions(store.userState, site),
      orgLimitsConnectedEdit: !isOrgOwner(store) && orgPref(store, 'userConnectedDisabled', false, false)
    };
  });

  if (!site || !page) {
    return null;
  }

  return <ConnectedSettingsContext.Provider value={connectedSiteContextValue}>
    <ChangesRequiredBase
      {...props}
      connected={connected}
      group={site}
      snippet={page}
      orgLimitsConnectedEdit={orgLimitsConnectedEdit}
      findSnippet={(name) => {
        // TODO PB import
      }}
      groupUpdateFn={/** @type {(data: ConnectedSettingsType) => Promise<void>} */ async(data) => {
        const updatedSite = await updateSite(site.id, data);
        if (updatedSite) {
          store.dispatch({
            type: 'UPDATE_SITE',
            data: { connected: updatedSite.connected },
          });
        }
      }}
    />
  </ConnectedSettingsContext.Provider>;
}
