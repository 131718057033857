import React, { useRef } from 'react';
import { PAGE_API_PREFIX } from '../../flags';
import DatabaseChip from '../Database/DatabaseChip';
import DatabaseList from '../Database/DatabaseList';
import SiteIconBasic from '@mui/icons-material/Web';
import { CreateDatabaseDialog } from '../Database/CreateDatabaseDialog';
import { useHistory } from 'react-router-dom';
import { createSite } from './apis';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { useTypedSelector } from '../../hooks';
import Grid from '@mui/material/Grid';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { Box } from '@mui/system';
import VerificationReminder from '../VerificationReminder/VerificationReminder';
import '../App/inner_main.css';
import SiteMenu from './SiteMenu';
import SpacesSearch from '../Database/SpacesSearch';
import DatabaseListItem from '../Database/DatabaseListItem';
import WelcomeToPageBlaze from './WelcomeToPageBlaze';

/**
 * @param {import('@store').OptionsViewSettings} sitesSettings
 */
function setUserSpacingSettings(sitesSettings) {
  storage.update(usersSettingsRef, {
    'options.sites_settings': sitesSettings,
  });
}

export default function SiteList() {
  let emailVerified = useTypedSelector(state => state.userState.emailVerified);

  return <div className="container-fluid page-body-with-scroll">
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} sm={12} md={12} style={{ height: '100%' }}>
        <ErrorBoundary>
          {emailVerified ? <SitesListInner /> : <Box sx={{ m: 4, mt: 6, width: 600, textAlign: 'left', ml: 'auto', mr: 'auto' }}><VerificationReminder reason="you will not be able to use Page Blaze" /></Box>}
        </ErrorBoundary>
      </Grid>
    </Grid>
  </div>;
}


function SitesListInner() {
  const sitesEndpoint = `${PAGE_API_PREFIX}/sites/`;
  const { push: navigate } = useHistory();
  const createSiteDialog = useRef(/** @type {{ show: () => void }} */ (null));

  /**
   * @type {import('@store').OptionsViewSettings}
   */
  const sitesSettings = useTypedSelector((state) =>
    state.userState.settingsLoaded && state.userState?.options && state.userState?.options.sites_settings
      ? state.userState?.options.sites_settings
      : { view: null, list_order_by: null }
  );

  const viewMode = sitesSettings.view || 'grid';

  function openCreateSiteDialog() {
    createSiteDialog.current.show();
  }

  /**
   * @type {import('../Database/CreateDatabaseDialog').CreateDatabaseDialogProps['createFn']}
   */
  async function createNewSite(config, name, icon, closeFn, done) {
    const site = await createSite(name, icon);
    if (site) {
      await storage.update(usersSettingsRef, {
        [`options.sites.${site.id}.favorite`]: true,
      });
      closeFn();
      navigate(`/site/${site.id}`);
    }

    done();
  }

  function getRenderItemProps({ key, site, refetch }) {
    return {
      link: `/site/${site.id}/`,
      updateIconEndpoint: `${sitesEndpoint}${site.id}/`,
      itemName:'Site',
      favoritesOptionKey: 'sites',
      defaultIcon: SiteIconBasic,
      renderMenu: (menuProps) => (<SiteMenu
        inDatabase={false}
        site={/** @type {SiteObjectType} */(site)}
        refetch={refetch}
        {...menuProps}
      />),
      key,
      database: site,
      refetch,
    };
  }

  return <>
    <DatabaseList
      favoritesOptionKey="sites"
      apiEndpoint={sitesEndpoint}
      wsPage="sites"
      wsRefreshEvents={[
        'site_created',
        'site_updated',
        'site_deleted',
        'site_shared',
        'site_unshared',
      ]}
      labels={{
        productName: 'Page Blaze',
        itemName: 'site',
        itemNamePlural: 'sites',
      }}
      onCreate={() => openCreateSiteDialog()}
      renderItem={(data, { key, database: site, refetch }) => (
        viewMode === 'grid'
          ? <DatabaseChip {...getRenderItemProps({ key,  site, refetch })}/>
          : <DatabaseListItem {...getRenderItemProps({ key,  site, refetch })} />
      )}
      renderSearch={(data, onClose) => <SpacesSearch
        spaces={data}
        onClose={onClose}
        itemTypePlural="sites"
        defaultIcon={SiteIconBasic}
        selectFn={(siteId) => navigate(`/site/${siteId}`)}
      />}
      setUserSettings={setUserSpacingSettings}
      userSettings={sitesSettings}
      renderEmptyComponent={(onCreate) => <WelcomeToPageBlaze onCreate={onCreate} />}
    />
    <CreateDatabaseDialog
      ref={createSiteDialog}
      itemType="site"
      createFn={createNewSite}
    />
  </>;
}
