import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Divider, LinearProgress, Link, List, ListItemButton, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/system/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import SiteIconBasic from '@mui/icons-material/Web';
import { getConnectedConfigOptions } from '../../flags';
import SnippetPreview from '../SnippetPreview/SnippetPreview';
import { makeConfig, snippetPreviewRemoteDbFn } from '../SnippetPreview/preview_utilities';
import { toast } from '../../message';
import './page.css';
import { getAuth, getIdToken } from 'firebase/auth';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { RemoteBottomStatusBar } from '../FormRenderer/FormRenderer';
// @ts-ignore
import logo from './blaze-logo.svg';
import { DEFAULT_THEME_COLOR } from './utils';


/**
 * @typedef {object} PageDataLink
 * @property {string} title
 * @property {string} slug
 */


/**
 * @typedef {object} PageData the structure of page objects returned from the page rendering engine's backend
 * @property {string} siteId
 * @property {string} siteSlug
 * @property {string} siteTitle
 * @property {string} siteIcon
 * @property {ConnectedSettingsType} siteConnected
 * @property {string} pageTitle
 * @property {string} pageSlug
 * @property {DeltaType} delta
 * @property {PageDataLink[]} links
 * @property {{themeColor?: string}=} themeOverrides
 */


/**
 * @param {object} props
 * @param {PageData} props.data
 * @param {import('@store').UserStateDef} props.userState
 * @param {React.CSSProperties=} props.containerStyle
 * @param {boolean=} props.previewMode
 * @param {boolean=} props.disableLinks
 * @param {string=} props.urlPrefix
 * @param {string=} props.currentPageSlug
 * @param {boolean=} props.loading
 */
export default function StandalonePage(props) {
  const { data } = props;
  const { config, connectedConfig } = useMemo(() => ({
    config: makePageConfig(props.userState, props.data.siteId),
    connectedConfig: getConnectedConfigOptions(
      null, { connected: props.data.siteConnected }, true,
    ).config,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [props.data.siteId]);
  const snippetPreviews = useRef(new Map());
  const LinkComponent = props.previewMode ? Link : RouterLink;
  const [mobileOpen, setMobileOpen] = useState(false);
  const homeSlug = data.links[0].slug;
  /** @type {(link: PageDataLink) => boolean} */
  const isSelected = (link) => (props.currentPageSlug === '/' && link.slug === homeSlug) || (link.slug === props.currentPageSlug);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const [remoteItems, setRemoteItems] = useState([]);
  const isRemotePending = remoteItems?.some(x => x.status === 'pending');
  const themeColor = props.data.themeOverrides?.themeColor || DEFAULT_THEME_COLOR;

  useEffect(() => {
    // when switching to a different page, reset remote items
    setRemoteItems(null);
  }, [props.data.pageSlug]);

  /**
   * @param {string=} pageSlug
   * @returns {string}
   */
  function getPageLink(pageSlug) {
    const siteLink = `${props.urlPrefix || '/'}${props.data.siteSlug}/`;
    if (!pageSlug || pageSlug === '/' || pageSlug === homeSlug) {
      return siteLink;
    }

    return `${siteLink}${pageSlug}/`;
  }

  if (!snippetPreviews.current.has(data.pageSlug)) {
    snippetPreviews.current.set(data.pageSlug, <SnippetPreview
      key={data.pageSlug}
      config={config}
      delta={data.delta}
      hideProMessage
      quickentry={false}
      connected={connectedConfig}
      onRemoteStatusUpdate={(items) => {
        setRemoteItems(items);
      }}
    />);
  }

  /**
   *
   * @param {string} pageSlug
   */
  function preview(pageSlug) {
    window.parent.postMessage({
      type: 'PAGE_PREVIEW',
      data: pageSlug,
    });
  }

  /**
   *
   * @param {string=} pageSlug
   * @return {Record<string, any> | null}
   */
  function linkProps(pageSlug) {
    if (props.previewMode) {
      return {
        onClick: () => preview(pageSlug),
      };
    }

    if (pageSlug === props.currentPageSlug) {
      return {
        disabled: true,
      };
    }

    return {
      to: getPageLink(pageSlug),
      disabled: props.disableLinks,
    };
  }

  return <>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      overflowY: 'auto',
      ...props.containerStyle,
    }}>
      <Box sx={{
        width: {
          xs: '100%',
          md: '50em',
          lg: '60em'
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        flexGrow: 1,
      }}>
        <AppBar
          sx={{
            backgroundColor: themeColor,
            my: {
              xs: 0,
              md: 1
            },
            borderRadius: {
              xs: 0,
              md: 8
            }
          }}
          component="nav"
          elevation={0}
          position="static"
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{
                pr: 2,
                display: 'flex',
                alignItems: 'center',
                pa: 0,
              }}>
                {data.siteIcon ? <div
                  translate="no"
                  style={{
                    filter: 'sepia(100%) hue-rotate(170deg) contrast(1.3)',
                    fontSize: '24px',
                    lineHeight: '24px',
                    width: 24,
                    height: 24,
                    color: 'initial',
                    verticalAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 8,
                  }}>{data.siteIcon}</div> : <SiteIconBasic sx={{ mr: 1 }} />}
                <Typography
                  noWrap
                  component={props.currentPageSlug === '/' ? undefined : LinkComponent}
                  {...linkProps('/')}
                  sx={{
                    mr: 2,
                    display: 'flex',
                    fontSize: 18,
                    fontWeight: 500,
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'none',
                      color: '#eee !important',
                    }
                  }}
                >
                  {data.siteTitle}
                </Typography>
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
                {data.links.map((link) => {
                  const selected = isSelected(link);
                  return (
                    <Box
                      key={link.slug}
                      sx={{
                        height: 64,
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        px: '2px',
                      }}
                    >
                      <Button
                        component={selected ? undefined : LinkComponent}
                        sx={{
                          color: '#fff',
                          fontWeight: 400,
                          backdropFilter: selected ? 'saturate(200%) contrast(150%) grayscale(20%)' : undefined,
                          '&.Mui-disabled' : {
                            color: '#fff',
                            fontWeight: 500,
                          },
                          '&:hover' : {
                            opacity: 1,
                          },
                          height: 32,
                        }}
                        {...linkProps(link.slug)}
                      >
                        {link.title}
                      </Button>
                    </Box>
                  );
                })}
              </Box>
              <div style={{ flexGrow: 1 }}></div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>
        <nav>
          <Drawer
            anchor="right"
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
            }}
          >
            <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={props.currentPageSlug === '/'}
                  component={props.currentPageSlug === '/' ? undefined : LinkComponent}
                  {...linkProps('/')}
                >
                  <Typography
                    noWrap
                    variant="h6"
                    sx={{ my: 0, textAlign: 'center', flexGrow: 1 }}
                  >
                    {!!data.siteIcon && <span style={{ marginRight: 2 }}>{data.siteIcon}</span>}{data.siteTitle}
                  </Typography>
                </ListItemButton>
              </ListItem>

              <Divider />
              <List>
                {data.links.map((link) => {
                  const selected = isSelected(link);
                  return (
                    <ListItem key={link.slug} disablePadding>
                      <ListItemButton
                        sx={{ textAlign: 'center' }}
                        selected={selected}
                        component={selected ? undefined : LinkComponent}
                        {...linkProps(link.slug)}
                      >
                        <ListItemText primary={link.title} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Drawer>
        </nav>
        <div className="container-fluid" style={{ flexGrow: 1 }}>
          {props.loading ? <LinearProgress variant="indeterminate" /> : null}
          <div style={{ display: props.loading ? 'none' : undefined }}>
            {[...snippetPreviews.current.entries()].map(([slug, comp]) => slug === data.pageSlug ? <div key={slug}>{comp}</div> : <div style={{ display: 'none' }} key={slug}>{comp}</div>)}
          </div>
        </div>
      </Box>
      <Box sx={{
        mt: 1,
        display: 'flex',
        justifyContent: 'center',
      }}>
        <RemoteBottomStatusBar
          items={remoteItems}
          containerStyle={{
            position: isRemotePending ? 'absolute' : 'relative',
            bottom: 0,
            left: 0,
          }}
        />
        <Box
          sx={{
            fontSize: 13,
            opacity: 0.9,
            display: 'inline-flex',
            textAlign: 'center',
            mx: 'auto',
          }}
        >
          <span>Powered by</span>
          <a href="https://page.blaze.today">
            <img
              src={logo}
              alt="Blaze"
              style={{ marginLeft: 8, marginTop: 1, maxWidth: 50 }}
            />
          </a>
        </Box>
      </Box>
    </div>
  </>;
}


/**
 * @param {import('@store').UserStateDef} userState
 * @param {string} siteId
 * @return {import("../../snippet_processor/DataContainer").Config}
 */
function makePageConfig(userState, siteId) {
  const { email, locale } = userState;
  return makeConfig({
    config: {
      snippet: {
        folderid: siteId,
      },
      user: {
        email: email || 'user@example.com',
      },
      locale,
      blockingErrorFn: (error) => {
        toast(error, {
          duration: 8000,
          intent: 'danger'
        });
      },
      commandWhitelistErrorFn: (command) => `You cannot use the {${command}} command in a page.`,
      commandWhitelist: [
        'FORMMENU',
        'FORMTEXT',
        'FORMPARAGRAPH',
        'FORMTOGGLE',
        'FORMDATE',
        'TIME',
        'USER',
        '=',
        'IF',
        'REPEAT',
        'ERROR',
        'SNIPPET',
        'URLLOAD',
        'NOTE',
        'RUN',
        'BUTTON',
        'DBSELECT',
        'IMAGE',
        // TODO PB imports
        // 'IMPORT',
      ],
      findSnippet: async(slug) => {
        // TODO PB imports
        return null;
      },
      remoteFn: async (node) => {
        if (node.info.type === 'dbselect') {
          return snippetPreviewRemoteDbFn(node, await getUserToken(), 'page');
        }
      },
    },
  });
}

async function getUserToken() {
  const currentUser = getAuth().currentUser;
  let token;
  if (currentUser) {
    token = await getIdToken(currentUser);
  } else {
    token = null;
  }
  return token;
}
