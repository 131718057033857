import { updateGroupOrSitePermissions } from '../../Sync/syncer';
import { store } from '@store';
import { doSafeTableRequest } from '../../hooks/useTables';
import { PAGE_API_PREFIX } from '../../flags';
import { toast } from '../../message';
import { getGroupOrSiteDatabaseQueries } from '../Utilities/getDatabaseQueries';
import { getState } from '../../getState';

const getSiteDatabaseQueriesFn = (/** @type {SiteObjectType} */site, /** @type {string} */databaseId) => getSiteDatabaseQueries(site.pages, databaseId);

/**
 * @param {string} databaseId
 * @param {string} query
 * @returns {Promise<void>}
 */
export async function updateSitePermissions(databaseId, query) {
  const getSiteFn = () => getState().sitesState.site;
  await updateGroupOrSitePermissions(getSiteFn, databaseId, query, updateSiteConnected, getSiteDatabaseQueriesFn);
}

/**
 * @param {SiteObjectType} site
 * @param {ConnectedSettingsType} connected
 * @returns {Promise<void>}
 */
async function updateSiteConnected(site, connected) {
  /** @type {boolean} */
  let isError;
  const updateBatch = { connected };
  const revertBatch = { connected: JSON.parse(JSON.stringify(site.connected || null)) };
  try {
    store.dispatch({
      type: 'UPDATE_SITE',
      data: updateBatch,
    });
    const siteOrError = await doSafeTableRequest(`${PAGE_API_PREFIX}/sites/${site.id}/`,'PATCH',
      updateBatch, { toastMessage: 'Error updating site\'s connected information.' });
    if (siteOrError.error) {
      isError = true;
      toast(`Error updating the site's connected information: ${siteOrError.detail}`, { duration: 6000, intent: 'danger' });
    } else {
      isError = false;
    }
  } catch {
    isError = false;
  } finally {
    if (isError) {
      store.dispatch({
        type: 'UPDATE_SITE',
        data: revertBatch,
      });
    }
  }
}

/**
 *
 * @param {PageObjectType[]} pages
 * @param {string} [onlyDatabaseId]
 * @returns {Promise<import("../../components/Utilities/getDatabaseQueries").ConnectedAttributes>}
 */
export async function getSiteDatabaseQueries(pages, onlyDatabaseId = null) {
  return getGroupOrSiteDatabaseQueries(pages.map(page => ({
    // clone the object as the 'getGroupOrSiteDatabaseQueries > createDom > parse > interleaveNodes'
    // function has a side effect on the passed object, in Text Blaze, decompressDelta is used and
    // a different object is returned before passed here. Therefore, such a side effect does not happen.
    delta: JSON.parse(JSON.stringify(page.current_revision.delta)),
  })), (name) => {
    // TODO PB import
    return null;
  }, onlyDatabaseId);
}
