import React from 'react';
import SharedIcon from '@mui/icons-material/PeopleAlt';
import PublishedIcon from '@mui/icons-material/Public';
import SiteIconBasic from '@mui/icons-material/Web';
import { Badge } from '@mui/material';

const SIZE = 28;

/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 * @param {React.CSSProperties} props.iconStyle
 */
export default function SiteIcon(props) {
  let iconStyle = props.iconStyle;
  if (!props.site.icon) {
    iconStyle = Object.assign({}, props.iconStyle, { display: 'inline-block' });
  }

  const icon = !!props.site.icon ? <div style={{
    width: SIZE,
    height: SIZE,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'initial',
    paddingTop: 1,
    paddingLeft: 0,
    fontSize: '22px',
    lineHeight: '22px',
  }}>
    {props.site.icon}
  </div> : <SiteIconBasic style={iconStyle} />;

  let miniIcon;
  if (props.site.is_published) {
    miniIcon = <PublishedIcon style={{
      fontSize: '14px'
    }} />;
  } else if (props.site.is_shared) {
    miniIcon = <SharedIcon style={{
      fontSize: '14px'
    }} />;
  } else {
    return icon;
  }

  return <Badge
    badgeContent={<div style={{
      borderRadius: 100,
      width: 16,
      height: 16,
      backgroundColor: '#999',
      overflow: 'hidden',
      border: 'solid 1px #999',
    }}>
      <div style={{
        position: 'relative',
        top: -2,
        color: 'white',
        height: 16,
        width: 16,
        lineHeight: '24px'
      }}>{miniIcon}</div>
    </div>}
    anchorOrigin={miniIcon && { vertical: 'bottom', horizontal: 'right' }}
    sx={{
      '.MuiBadge-badge': {
        bottom: 5,
      },
    }}
  >
    {icon}
  </Badge>;
}
