import React from 'react';
import { ConnectedGroupButtonBase } from '../Group/ConnectedGroupButton';
import ConnectedSettingsContext from '../Group/ConnectedSettingsContext';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { getSiteDatabaseQueries } from './site_db_utilities';
import { connectedSiteContextValue } from './utils';


/**
 * @param {object} props
 * @param {function} props.groupUpdateFn
 * @param {boolean} props.isOwner
 * @param {boolean} props.shareBlocksConnect
 */
function ConnectedSiteButtonBase(props) {
  const {
    loaded,
    site,
  } = useTypedSelectorShallowEquals((store) => {
    const site = store.sitesState.site;
    if (!site) {
      return { loaded: false };
    }

    return {
      loaded: true,
      site,
    };
  });

  return <ConnectedSettingsContext.Provider value={connectedSiteContextValue}>
    <ConnectedGroupButtonBase
      {...props}
      groupId={site?.id}
      loaded={loaded}
      settings={site?.connected}
      snippets={site?.pages}
      getDatabaseQueriesFn={getSiteDatabaseQueries}
    />
  </ConnectedSettingsContext.Provider>;
}


const ConnectedSiteButton = React.memo(ConnectedSiteButtonBase);
export default ConnectedSiteButton;