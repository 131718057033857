import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import { useIsXSmall } from '../../hooks';
import Tooltip from '@mui/material/Tooltip';
import AddPageIcon from '@mui/icons-material/Add';
import { createPage } from './apis';
import { useHistory } from 'react-router-dom';
import { store } from '@store';
import useHotkey from '../../hotkeys_hook';


/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 */
function CreatePageFabBase(props) {
  const { push: navigate } = useHistory();
  const { site } = props;
  const isXSmall = useIsXSmall();
  const [creating, setCreating] = useState(false);

  useHotkey('n+p', () => {
    if (!site) {
      return;
    }

    addNewPage();
  });

  if (!site) {
    return null;
  }


  async function addNewPage() {
    setCreating(true);
    try {
      const newPage = await createPage(site.id);
      if (newPage) {
        store.dispatch({
          type: 'CREATE_PAGE',
          data: newPage,
        });
        navigate(`/site/${site.id}/page/${newPage.id}`);
      }
    } finally {
      setCreating(false);
    }
  }

  return <div className="fab-add">
    <div style={{
      position: 'fixed',
      left: 30,
      top: 18,
      zIndex: 15,
      cursor: 'pointer'
    }}>
      <Fab
        disabled={creating}
        onClick={addNewPage}
        color="primary"
        variant={isXSmall ? 'extended' : undefined}
      >
        <Tooltip
          title="Add a page"
          placement="bottom"
          arrow
        >
          <AddPageIcon style={{ color: 'white', height: 56, fontSize: '240%' }}/>
        </Tooltip>
        {isXSmall ? 'New page' : null}
      </Fab>
    </div>
  </div>;
}

const CreatePageFab = React.memo(CreatePageFabBase);
export default CreatePageFab;
