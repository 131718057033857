import '../../firebase_init'; // Initialize firestore app
import { combineReducers, createStore } from 'redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { loginAuthSession } from '../../session_utils';

/**
 * @typedef {import('@store').UserStateDef} PageEngineStateDef
 */

/**
 * Page Blaze Engine user reducer
 * @param {PageEngineStateDef} state
 * @param {Record<string, any> & {type: string}} action
 * @return {PageEngineStateDef}
 */
export const userReducer = function(state = {
  isLoaded: false,
}, action) {
  switch (action.type) {
  case 'USER_LOGIN':
  {
    return Object.assign({}, state, action.user);
  }
  default:
    return state;
  }
};

const combinedReducers = combineReducers({
  userState: userReducer,
});

export let pbeStore = createStore(combinedReducers);

onAuthStateChanged(getAuth(), function(user) {
  if (user) {
    loginAuthSession(user);
    pbeStore.dispatch({
      type: 'USER_LOGIN',
      user: {
        isLoaded: true,
        uid: user.uid,
        email: user.email,
      }
    });
  } else {
    pbeStore.dispatch({
      type: 'USER_LOGIN',
      user: {
        isLoaded: true
      }
    });
  }
});
