import { showTrashConfirmation, toast } from '../../message';
import React from 'react';
import { doSafeTableRequest } from '../../hooks/useTables';
import { PAGE_API_PREFIX } from '../../flags';
import { store } from '@store';

export const getSiteUrl = (/** @type {string} */ siteId) => `${PAGE_API_PREFIX}/sites/${siteId}/`;
export const getPageUrl = (pageId) => `${PAGE_API_PREFIX}/pages/${pageId}/`;

const NEW_PAGE_PREFIX = 'Page ';
const NEW_PAGE_SLUG_PREFIX = 'page';


/**
 * Fetch a site by id without loading it into the store
 * @param {string} siteId
 * @returns {Promise<SiteObjectType>}
 */
export async function fetchSite(siteId) {
  try {
    const siteOrError = await doSafeTableRequest(`${PAGE_API_PREFIX}/sites/${siteId}/`, 'GET');
    if (siteOrError?.error) {
      return null;
    }

    return /** @type {SiteObjectType} */ (siteOrError);
  } catch {
    return null;
  }
}

/**
 * Load a site by the given id
 * @param {string} siteId
 */
export async function loadSite(siteId) {
  const data = await doSafeTableRequest(`${PAGE_API_PREFIX}/sites/${siteId}/`, 'GET');
  if (data.error) {
    store.dispatch({
      type: 'CLEAR_SITE',
    });
    throw Error(data.error);
  } else {
    store.dispatch({
      type: 'INIT_SITE',
      data,
    });
  }
}

/**
 * Create a new site
 * @param {string} name
 * @param {string=} icon
 * @returns {Promise<SiteObjectType>}
 */
export async function createSite(name, icon) {
  try {
    const siteOrError = await doSafeTableRequest(`${PAGE_API_PREFIX}/sites/`,
      'POST',
      {
        name,
        icon,
      }, {
        toastMessage: 'An error occurred creating a new site.',
      });
    if (siteOrError.error) {
      toast(`Error creating a new site: ${siteOrError.detail}`, { duration: 6000, intent: 'danger' });
      return null;
    }

    return siteOrError;
  } catch {
    return null;
  }
}


/**
 * Immediately update a site
 * @param {string} siteId
 * @param {any} data
 * @param {string} toastMessage
 * @returns {Promise<SiteObjectType>}
 */
export async function updateSite(siteId, data, toastMessage = 'An error occurred updating the site.') {
  try {
    const siteOrError = await doSafeTableRequest(getSiteUrl(siteId),'PATCH', data, { toastMessage });
    if (siteOrError.error) {
      toast(`Error updating the site: ${siteOrError.detail}`, { duration: 6000, intent: 'danger' });
      return null;
    }

    return /** @type {SiteObjectType} */ (siteOrError);
  } catch {
    return null;
  }
}

/**
 * Delete a site after showing a confirmation dialog
 * @param {SiteObjectType} site
 * @param {'context_menu' | 'site_menu'} source
 * @returns {Promise<boolean>}
 */
export async function deleteSite(site, source = 'context_menu') {
  return new Promise((resolve) => {
    showTrashConfirmation({
      item: <><b>{site.name}</b> site</>,
      onDelete: async () => {
        const deleteResponse = await doSafeTableRequest(getSiteUrl(site.id), 'DELETE', null, { toastMessage: 'An error occurred deleting the site.' });
        if (deleteResponse?.error) {
          toast(`Error deleting site: ${deleteResponse.detail}`, { duration: 6000, intent: 'danger' });
          resolve(false);
        } else {
          toast('The site has been moved to the trash.', { intent: 'success' });
          resolve(true);
        }
      },
      onCancel: () => resolve(false),
    });
  });
}

/**
 * Publish a site
 * @param {string} siteId
 */
export async function publishSite(siteId) {
  return updateSite(siteId, { is_published: true }, 'An error occurred publishing this site.');
}

/**
 * Unpublish a site
 * @param {string} siteId
 */
export async function unpublishSite(siteId) {
  return updateSite(siteId, { is_published: false }, 'An error occurred unpublishing this site.');
}


/**
 * Create a new page in the given site
 * @param {string} siteId
 * @returns {Promise<PageObjectType|null>}
 */
export async function createPage(siteId) {
  const site = store.getState().sitesState.site;
  const pageNames = new Set(site.pages.map(p => p.current_revision.name));
  const pageSlugs = new Set(site.pages.map(p => p.slug.toLowerCase()));
  let numericPostfix = site.pages.length + 1;
  let newPageName = `${NEW_PAGE_PREFIX}${numericPostfix}`;
  let newPageSlug = `${NEW_PAGE_SLUG_PREFIX}${numericPostfix}`;
  while (pageNames.has(newPageName) || pageSlugs.has(newPageSlug)) {
    numericPostfix++;
    newPageName = `${NEW_PAGE_PREFIX}${numericPostfix}`;
    newPageSlug = `${NEW_PAGE_SLUG_PREFIX}${numericPostfix}`;
  }

  try {
    const newPageOrError = await doSafeTableRequest(`${PAGE_API_PREFIX}/sites/${siteId}/pages/`,
      'POST',
      {
        name: newPageName,
        slug: newPageSlug,
      }, {
        toastMessage: 'An error occurred creating a new page.',
      });


    if (newPageOrError.error) {
      toast(`Error creating a new page: ${newPageOrError.detail}`, { duration: 6000, intent: 'danger' });
      return null;
    }

    return /** @type {PageObjectType} */ (newPageOrError);
  } catch (e) {
    return null;
  }
}


/**
 * Immediately update a page
 * @param {string} pageId
 * @param {Partial<PageObjectType | PageRevision>} data
 * @returns {Promise<null|PageObjectType>}
 */
export async function updatePage(pageId, data) {
  try {
    const pageOrError = await doSafeTableRequest(getPageUrl(pageId),'PATCH', data, { toastMessage: 'An error occurred updating the page.' });
    if (pageOrError.error) {
      toast(`Error updating the page: ${pageOrError.detail}`, { duration: 6000, intent: 'danger' });
      return null;
    }

    return pageOrError;
  } catch {
    return null;
  }
}

/**
 * Delete a page after showing a confirmation dialog
 * @param {PageObjectType} page
 * @returns {Promise<boolean>}
 */
export async function deletePage(page) {
  return new Promise((resolve) => {
    showTrashConfirmation({
      item: <><b>{page.current_revision.name}</b> page</>,
      onDelete: async () => {
        const deleteResponse = await doSafeTableRequest(getPageUrl(page.id), 'DELETE', null, { toastMessage: 'An error occurred deleting the site.' });
        if (deleteResponse?.error) {
          toast(`Error deleting page: ${deleteResponse.detail}`, { duration: 6000, intent: 'danger' });
          resolve(false);
        } else {
          toast('The page has been moved to the trash.', { intent: 'success' });
          resolve(true);
        }
      },
      onCancel: () => resolve(false),
    });
  });
}

/**
 * Publish page revision
 * @param {string} pageId
 */
export async function publishPageRevision(pageId) {
  try {
    const publishResponse = await doSafeTableRequest(`${PAGE_API_PREFIX}/revisions/${pageId}/publish/`,
      'POST',
      {}, {
        toastMessage: 'An error occurred publishing the page.',
      });
    if (publishResponse?.error) {
      toast(`Error publishing the page's revision: ${publishResponse.detail}`, { duration: 6000, intent: 'danger' });
      return false;
    }
    store.dispatch({ type: 'UPDATE_PAGE_REVISION', pageId, data: { is_draft: false } });
    return true;
  } catch {
    return false;
  }
}

/**
 * Change the page order in the site
 * @param {string} pageId
 * @param {number} newOrder
 * @param {number} oldOrder
 */
export async function movePage(pageId, newOrder, oldOrder) {
  let isError;
  try {
    store.dispatch({
      type: 'UPDATE_PAGE',
      pageId,
      data: {
        order: newOrder,
      },
    });
    store.dispatch({ type: 'SORT_PAGES' });
    const response = await doSafeTableRequest(`${PAGE_API_PREFIX}/pages/${pageId}/move/`,
      'POST',
      {
        new_order: newOrder,
      }, {
        toastMessage: 'An error occurred moving the page.',
      });
    if (response?.error) {
      toast(`Error moving page: ${response.detail}`, { duration: 6000, intent: 'danger' });
      isError = true;
    } else {
      isError = false;
    }
  } catch {
    isError = true;
  }

  if (isError) {
    store.dispatch({
      type: 'UPDATE_PAGE',
      pageId,
      data: {
        order: oldOrder,
      },
    });
    store.dispatch({ type: 'SORT_PAGES' });
  }
}


/**
 * Restore page revision
 * @param {string} pageId
 * @param {PageRevision} revision
 * @returns {Promise<PageRevision|null>}
 */
export async function restorePageRevision(pageId, revision) {
  try {
    const revisionOrError = await doSafeTableRequest(`${PAGE_API_PREFIX}/revisions/${revision.id}/restore/`,
      'POST',
      {}, {
        toastMessage: 'An error occurred restoring the revision.',
      });
    if (revisionOrError.error) {
      toast(`Error restoring revision: ${revisionOrError.detail}`, { duration: 6000, intent: 'danger' });
      return null;
    }
    return /** @type {PageRevision} */ (revisionOrError);
  } catch {
    return null;
  }
}

/**
 * Get page revisions
 * @param {string} pageId
 * @returns {Promise<PageRevision[]|null>}
 */
export async function getPageRevisions(pageId) {
  try {
    const revisionsOrError = await doSafeTableRequest(`${PAGE_API_PREFIX}/pages/${pageId}/revisions/`,
      'GET',
      null,
      {
        toastMessage: 'An error occurred getting page revisions.',
      });
    if (revisionsOrError?.error) {
      toast(`Error getting page revisions: ${revisionsOrError.detail}`, { duration: 6000, intent: 'danger' });
      return null;
    }
    return /** @type {PageRevision[]} */ (revisionsOrError);
  } catch {
    return null;
  }
}
