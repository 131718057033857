import React from 'react';
import ModifiedBadge from '../SnippetList/ModifiedBadge';


/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 * @param {PageObjectType} props.page
 * @param {boolean} props.selected
 */
function PageModifiedBadgeBase(props) {
  const { site, page } = props;
  const createdDate = new Date(page.created_on).getTime();
  const createdUID = page.created_by;
  const modifiedDate = new Date(page.current_revision.updated_on).getTime();
  const modifiedUID = page.current_revision.updated_by;

  return <ModifiedBadge
    selected={props.selected}
    groupId={site.id}
    snippetId={page.id}
    createdDate={createdDate}
    createdUID={createdUID}
    modifiedDate={modifiedDate}
    modifiedUID={modifiedUID}
  />;
}


const PageModifiedBadge = React.memo(PageModifiedBadgeBase);
export default PageModifiedBadge;
