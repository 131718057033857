import React, { forwardRef, useEffect, useState } from 'react';
import StandalonePage from './StandalonePage';
import NotFound from '../NotFound/NotFound';
import { useTypedSelector } from '../../hooks';
import { waitForLogin } from '@store';

export function PagePreviewEmbed() {
  const userState = useTypedSelector(store => store.userState);
  let initialPreviewData;
  const previewDataJson = window.frameElement?.getAttribute('data-preview');
  if (previewDataJson) {
    initialPreviewData = JSON.parse(previewDataJson);
  }
  const [previewData, setPreviewData] = useState(initialPreviewData);

  useEffect(() => {
    (async () => {
      await waitForLogin();
      window.parent?.postMessage({ type: 'PAGE_LOADED' });
    })();

    const listener = async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const type = event.data.type;
      if (type === 'SET_PAGE_DATA') {
        setPreviewData(event.data.data);
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);

  if (!previewData) {
    return <NotFound />;
  }

  if (!userState.uid) {
    return null;
  }

  return <StandalonePage
    previewMode
    currentPageSlug={previewData.pageSlug}
    key={previewData.pageSlug}
    data={previewData}
    userState={userState}
  />;
}

/**
 * @param {object} props
 * @param {boolean} props.loading
 * @param {import("./StandalonePage").PageData} props.data
 * @param {React.CSSProperties=} props.containerStyle
 */

/**
 * @typedef {object} PagePreviewFrameProps
 * @property {boolean} loading
 * @property {import("./StandalonePage").PageData} data
 * @property {React.CSSProperties=} containerStyle
 * @property {React.Ref<HTMLIFrameElement>=} ref
 */

/**
 * @type {React.FC<PagePreviewFrameProps>}
 */
export const PagePreviewFrame = forwardRef((props, ref) => {
  const userState = useTypedSelector(store => store.userState);
  return <>
    <iframe
      ref={ref}
      src="/preview/"
      title="Page preview"
      data-preview={JSON.stringify(props.data)}
      style={{
        ...props.containerStyle,
        border: 'none',
        display: props.loading ? 'hidden' : undefined,
      }}
    />
    {props.loading && <StandalonePage
      previewMode
      disableLinks
      currentPageSlug={props.data.pageSlug}
      data={props.data}
      containerStyle={{
        ...props.containerStyle,
        pointerEvents: 'none',
      }}
      userState={userState}
    />}
  </>;
});
