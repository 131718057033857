import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Divider from '@mui/material/Divider';
import React from 'react';
import ActivatePageIcon from '@mui/icons-material/FlashOnOutlined';
import DeactivatePageIcon from '@mui/icons-material/FlashOffOutlined';
import ListPageIcon from '@mui/icons-material/Link';
import SetAsMainPageIcon from '@mui/icons-material/Home';
import UnlistPageIcon from '@mui/icons-material/LinkOff';
import { deletePage, movePage, updatePage } from './apis';
import { store } from '@store';
import { useHistory } from 'react-router-dom';
import AsyncMenuItem from './AsyncMenuItem';


/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 * @param {PageObjectType} props.page
 * @param {function} props.onClose
 * @param {boolean} props.isHome
 */
export default function PageMenu(props) {
  const { site, page, onClose } = props;
  const { push: navigate } = useHistory();

  /**
   * @param {Partial<PageObjectType>} updateBatch
   * @returns {Promise<void>}
   */
  async function updatePageAndStore(updateBatch) {
    const updatedPage = await updatePage(page.id, updateBatch);
    if (updatedPage) {
      store.dispatch({
        type: 'UPDATE_PAGE',
        pageId: page.id,
        data: updatedPage,
      });
      onClose();
    }
  }

  async function handleDeletePage() {
    const isDeleted = await deletePage(page);
    if (isDeleted) {
      navigate(`/site/${site.id}/`);
      store.dispatch({
        type: 'DELETE_PAGE',
        pageId: page.id,
      });
      onClose();
    }
  }

  async function handleSetAsMain() {
    await movePage(props.page.id, Math.min(...props.site.pages.map(p => p.order)) - 1, props.page.order);
    onClose();
  }

  return <>
    <AsyncMenuItem
      text="Set as home"
      tooltip="Set this page as the home page for your site."
      disabledTooltip={!page.is_active ? 'Activate this page first to be able to set as the home page.' : 'This is already the site\'s home page'}
      icon={SetAsMainPageIcon}
      disabled={!page.is_active || props.isHome}
      onClick={handleSetAsMain}
    />
    {page.is_listed ? <AsyncMenuItem
      text="Unlist"
      tooltip="Don't show this page in your site's navigation bar."
      icon={UnlistPageIcon}
      onClick={() => updatePageAndStore({ is_listed: false })}
    /> : <AsyncMenuItem
      tooltip="Show this page in your site's navigation bar."
      text="List"
      icon={ListPageIcon}
      onClick={() => updatePageAndStore({ is_listed: true })}
    />}
    {page.is_active ? <AsyncMenuItem
      text="Deactivate"
      tooltip="Deactivate this page in your site. Visitors will see a not found page when opened."
      disabledTooltip="You cannot deactivate your home page."
      icon={DeactivatePageIcon}
      disabled={props.isHome}
      onClick={() => updatePageAndStore({ is_active: false })}
    /> : <AsyncMenuItem
      tooltip="Activate this page in your site. Visitors will see a not found when opened."
      text="Activate"
      icon={ActivatePageIcon}
      onClick={() => updatePageAndStore({ is_active: true })}
    />}
    <Divider />
    <AsyncMenuItem
      text="Delete page…"
      icon={DeleteIcon}
      disabled={site.pages.length === 1}
      onClick={handleDeletePage}
    />
  </>;
}
