import React, { useState } from 'react';
import CreatePageFab from './CreatePageFab';
import { useTypedSelector, useTypedSelectorShallowEquals } from '../../hooks';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { iconStyleFn, innerDivStyle, itemStyleFn } from '../SnippetList/shared';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useHistory, useParams } from 'react-router-dom';
import SiteIcon from './SiteIcon';
import SiteMenu from './SiteMenu';
import { ClickAwayListener, Menu, Tooltip } from '@mui/material';
import DatabaseShareDialog from '../Database/DatabaseShareDialog';
import { store } from '@store';
import useUncommittedStore from '../../hooks/useUncommittedStore';
import PageMenu from './PageMenu';
import PageSlug from './PageSlug';
import PageModifiedBadge from './PageModifiedBadge';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { movePage } from './apis';
import { ConnectedAvatarGroup } from '../Database/DatabaseAvatarGroup';
import ConnectedIcon from '@mui/icons-material/CloudOutlined';
import { useDispatch } from 'react-redux';
import SiteSearch from './SiteSearch';
import { getSiteHomePageId } from './utils';


const PAGE_DRAGGABLE_ID_PREFIX = 'PAGE/';


/**
 * @param {object} props
 * @param {React.CSSProperties=} props.styleContent
 */
export default function SiteNavigator(props) {
  const { push: navigate } = useHistory();
  const [contextClick, setContextClick] = useState(null);
  const site = useUncommittedStore(store => store.sitesState.site);
  const selection = useTypedSelectorShallowEquals(state => state.sitesState.selection);
  const { tab } = /** @type {{ tab: ('sharing') }}  */  (useParams());
  const dispatch = useDispatch();
  const siteSearchOpen = useTypedSelector(store => store.uiState.isSearchOpened);

  if (!site) {
    return null;
  }

  const isSiteSelected = selection.id === site.id;
  const navigateToSite = () => navigate(`/site/${site.id}/`);
  const contextClose = () => setContextClick(null);
  const isEditor = site.user_permission !== 'viewer';
  const isOwner = site.user_permission === 'owner';
  const editable = isEditor; // TODO PB connected editing blocked
  const isConnected = !!site.connected?.is_connected;
  const connectedSpaces = site.connected?.database_queries ? Object.keys(site.connected?.database_queries).sort() : [];
  const homePageId = getSiteHomePageId(site);

  function renderSiteContextMenu() {
    if (!contextClick) {
      return null;
    }

    return <ClickAwayListener onClickAway={contextClose} mouseEvent="onMouseDown">
      <SiteMenu
        open
        inDatabase
        site={site}
        onClose={contextClose}
        menuProps={{
          sx: {
            pointerEvents: 'none'
          },
          PaperProps: {
            sx: {
              pointerEvents: 'auto'
            },
          },
          keepMounted: true,
          disableAutoFocusItem: true,
          transitionDuration: 0,
          anchorReference: 'anchorPosition',
          anchorPosition: { top: contextClick.y, left: contextClick.x },
        }}
        onRenamed={(name) => {
          store.dispatch({
            type: 'UPDATE_SITE',
            data: { name },
          });
        }}
        onPublished={(published) => {
          store.dispatch({
            type: 'UPDATE_SITE',
            data: { is_published: published },
          });
        }}
        onDeleted={() => navigate('/')}
      />
    </ClickAwayListener>;
  }

  /**
   * @param {string} pageId
   * @param {number} newIndex
   * @returns {Promise<void>}
   */
  async function onMovePage(pageId, newIndex) {
    const page = store.getState().sitesState.pagesMap[pageId];
    const currentIndex = site.pages.findIndex(p => p.id === page.id);
    if (currentIndex === newIndex) {
      return;
    }

    const oldOrder = page.order;
    const sitePages = site.pages.slice();
    sitePages.splice(currentIndex, 1);

    let newOrder;
    if (sitePages.length === 0) {
      newOrder = Math.random();
    } else if (newIndex === 0) {
      newOrder = sitePages[0].order - Math.random();
    } else if (newIndex >= sitePages.length) {
      newOrder = sitePages[sitePages.length - 1].order + Math.random();
    } else {
      newOrder = (sitePages[newIndex - 1].order + sitePages[newIndex].order) / 2;
    }

    await movePage(page.id, newOrder, oldOrder);
  }

  return <div style={{
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: 'solid 1px #e0e0e0'
  }}>
    <CreatePageFab site={site} />
    {renderSiteContextMenu()}

    <div style={{
      position: 'relative',
      flex: 1,
      display: 'block',
      paddingRight: 15,
      overflowY: 'auto',
    }}>
      <List
        dense
        disablePadding
        sx={{
          paddingTop: {
            xs: '84px',
            sm: '36px'
          },
          paddingBottom: '20px'
        }}
        style={props.styleContent}
      >
        <ListItem
          dense
          button
          disableGutters
          disableRipple
          onClick={navigateToSite}
          style={Object.assign({
            opacity: 1,
          }, itemStyleFn({
            selected: isSiteSelected,
            depth: 0,
          }))}
          onContextMenu={event => {
            event.preventDefault();
            navigateToSite();
            setContextClick({
              id: site.id,
              x: event.clientX - 2,
              y: event.clientY - 4,
            });
          }}
        >
          <ListItemIcon style={{
            minWidth: 44
          }}>
            <SiteIcon
              site={site}
              iconStyle={iconStyleFn({
                selected: isSiteSelected,
              })}
            />
          </ListItemIcon>
          <ListItemText
            style={{ paddingLeft: 0, margin: 0 }}
            primary={<div style={Object.assign({
              display: 'flex',
              flexFlow: 'row nowrap',
              justifyContent: 'space-between',
              alignItems: 'center'
            }, innerDivStyle(true))}>
              <div style={{
                flex: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 'bold'
              }}>
                {site.name}
              </div>
              {isConnected ? <>
                {connectedSpaces.length ?
                  <ConnectedAvatarGroup
                    isOwner={isOwner}
                    editUrl={`/site/${site.id}/connected`}
                    spaces={connectedSpaces} /> :
                  <Tooltip title="Connected snippet folder">
                    <ConnectedIcon style={{
                      opacity: .5,
                      verticalAlign: 'middle',
                      position: 'relative',
                      top: -1,
                      paddingLeft: 2,
                      marginLeft: 4
                    }} fontSize="small" />
                  </Tooltip>}
              </> : null}
            </div>} />
        </ListItem>
        <DragDropContext
          onDragEnd={async (result)=>{
            const destination = result.destination;
            if (!destination) {
              return;
            }

            if (destination.droppableId === 'PAGES' && result.draggableId.startsWith(PAGE_DRAGGABLE_ID_PREFIX)) {
              await onMovePage(result.draggableId.slice(PAGE_DRAGGABLE_ID_PREFIX.length), destination.index);
            }
          }}
        >
          <Droppable droppableId="PAGES">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {site.pages.map((page, index) => <Draggable
                  key={page.id}
                  index={index}
                  draggableId={`${PAGE_DRAGGABLE_ID_PREFIX}${page.id}`}
                  isDragDisabled={!editable}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={provided.draggableProps.style}
                    >
                      <PageItem
                        site={site}
                        page={page}
                        selected={selection.id === page.id}
                        isHome={page.id === homePageId}
                      />
                    </div>
                  )}</Draggable>)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </div>
    {site.user_permission === 'owner' && tab === 'sharing' && <DatabaseShareDialog
      database={site}
      onClose={() => {
        navigate(`/site/${site.id}/`);
      }}
      entityType="site"
      refetch={(data) => {
        store.dispatch({
          type: 'UPDATE_SITE',
          data,
        });
      }}
    />}
    { siteSearchOpen && <SiteSearch
      onClose={() => dispatch({ type: 'CLOSE_SEARCH' })}
    /> }
  </div>;
}


/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 * @param {PageObjectType} props.page
 * @param {boolean} props.selected
 * @param {boolean} props.isHome
 */
function PageItem(props) {
  const { push: navigate } = useHistory();
  const { site, page, selected } = props;
  const style = itemStyleFn({ selected, depth: 1 });
  const navigateToPage = () => navigate(`/site/${page.site_id}/page/${page.id}/`);
  const [contextClick, setContextClick] = useState(null);
  const contextClose = () => setContextClick(null);


  function renderPageContextMenu() {
    if (!contextClick) {
      return null;
    }

    return <ClickAwayListener onClickAway={contextClose} mouseEvent="onMouseDown">
      <Menu
        open
        onClose={contextClose}
        onContextMenu={(evt) => {
          evt.preventDefault();
          contextClose();
        }}
        sx={{
          pointerEvents: 'none'
        }}
        PaperProps={{
          sx: {
            pointerEvents: 'auto'
          }
        }}
        keepMounted
        disableAutoFocusItem
        transitionDuration={0}
        anchorReference="anchorPosition"
        anchorPosition={{ top: contextClick.y, left: contextClick.x }}
      >
        <PageMenu
          site={site}
          page={page}
          onClose={contextClose}
          isHome={props.isHome}
        />
      </Menu>
    </ClickAwayListener>;
  }

  return <>
    {renderPageContextMenu()}
    <ListItem
      dense
      button
      disableGutters
      disableRipple
      style={style}
      onClick={navigateToPage}
      onContextMenu={event => {
        event.preventDefault();
        navigateToPage();
        setContextClick({
          x: event.clientX - 2,
          y: event.clientY - 4,
        });
      }}
    >
      <PageModifiedBadge
        selected={selected}
        page={page}
        site={site}
      />
      <ListItemText style={{ paddingRight: 5, margin: 0, paddingLeft: 12 }} primary={<div style={Object.assign({
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        opacity: 1,
      }, innerDivStyle(false))}>
        <div style={{
          flex: 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>{page.current_revision.name}</div>
        <PageSlug
          slug={page.slug}
          isMain={props.isHome}
          isActive={page.is_active}
          isListed={page.is_listed}
        />
      </div>}/>
    </ListItem>
  </>;
}