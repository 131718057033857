import React, { useRef, useState } from 'react';
import CommentsButton from '../Comments/CommentsButton';
import { IconButton, Menu, SwipeableDrawer, Tooltip } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Devices';
import BufferedEditableText from '../BufferedEditableText/BufferedEditableText';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import SlugEditor from './SlugEditor';
import OptionsIcon from '@mui/icons-material/MoreHoriz';
import PageMenu from './PageMenu';
import { toast } from '../../message';
import { getSiteHomePageId, getSiteBaseUrl, validatePageSlug } from './utils';
import PageRevisionsSidebar from './PageRevisionsSidebar';
import { useHistory, useLocation } from 'react-router-dom';
// @ts-ignore
import activityHistory from '../ChangeHistory/activity_history.svg';


/**
 * @param {object} props
 * @param {SiteObjectType} props.site
 * @param {PageObjectType} props.page
 * @param {(revision?: PageRevision|null) => void} props.onPreview
 * @param {(name: string) => void} props.handleName
 * @param {(slug: string) => void} props.handleSlug
 * @param {boolean} props.editable
 */
export default function PageToolbar(props) {
  const { page, site } = props;
  const actionsMenuRef = useRef(null);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const showRevisions = location.hash === '#revisions';
  const setShowRevisions = (value)  => history.push({ hash: value ? 'revisions' : '' });

  const renderVerticalDivider = () => <Divider
    orientation="vertical"
    flexItem
    sx={{ margin: '0 6px' }}
  />;

  return <>
    <Paper
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 2,
        padding: '4px 12px 4px 24px',
      }}
      elevation={2}
    >
      <div style={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
      }}>
        <BufferedEditableText
          disabled={!props.editable}
          placeholder="Edit title..."
          selectAllOnFocus
          minWidth={0}
          value={page && page.current_revision.name}
          onChange={props.handleName}
          style={{
            overflow: 'hidden',
            fontSize: 18,
          }}
          validate={(value) => {
            value = value.trim();
            if (value === '') {
              toast('The page title cannot be blank.', {
                duration: 4000,
                intent: 'danger'
              });
              return false;
            }
            return true;
          }}
        />
        <IconButton
          size="small"
          style={{
            marginLeft: 12,
            marginTop: 1,
            padding: 0,
            width: 1,
            flexBasis: 'fit-content',
          }}
          ref={actionsMenuRef}
          onClick={async (e) => {
            setContextMenuOpen(true);
            e.stopPropagation();
          }}
        >
          <OptionsIcon
            fontSize="small"
          />
        </IconButton>
      </div>
      {renderVerticalDivider()}
      <SlugEditor
        value={page.slug}
        baseUrl={`${getSiteBaseUrl(site)}/${site.slug}/`}
        path={`${page.slug}/`}
        placeholder="page_slug"
        style={{
          width: 500,
        }}
        onChange={(newSlug) => props.handleSlug(newSlug)}
        validate={validatePageSlug}
        readOnly={!props.editable}
      />
      <Tooltip title="Preview page">
        <IconButton
          onClick={() => props.onPreview(null)}
          size="large"
        >
          <PreviewIcon />
        </IconButton>
      </Tooltip>
      {renderVerticalDivider()}
      {props.editable && <Tooltip title="Page revisions">
        <IconButton
          onClick={() => setShowRevisions(true)}
          size="large">
          <img
            src={activityHistory}
            style={{
              width: 24,
              height: 24,
              opacity: .6
            }}
            alt="History"
            draggable="false"
          />
        </IconButton>
      </Tooltip>}
      <CommentsButton entityType="page" entityId={props.page.id} />
    </Paper>
    <Menu
      anchorEl={actionsMenuRef.current}
      open={contextMenuOpen}
      onClose={() => {
        setContextMenuOpen(false);
      }}
      onContextMenu={(evt) => {
        evt.preventDefault();
      }}
    >
      <PageMenu
        site={site}
        page={page}
        onClose={() => setContextMenuOpen(false)}
        isHome={getSiteHomePageId(site) === page.id}
      />
    </Menu>
    <SwipeableDrawer
      onOpen={() => {}}
      anchor="right"
      open={showRevisions}
      onClose={() => setShowRevisions(false)}
      PaperProps={{
        sx: {
          width: 560,
        },
      }}
    >
      {showRevisions && <PageRevisionsSidebar
        page={props.page}
        closeSidebarFn={() => setShowRevisions(false)}
        onPreview={props.onPreview}
      />}
    </SwipeableDrawer>
  </>;
}
