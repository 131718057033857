import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Site from './Site';
import Page from './Page';


export default function SitePanel() {
  return <Switch>
    <Route exact path="/site/:id/page/:pid/" render={() => <ErrorBoundary>
      <Page />
    </ErrorBoundary>}/>
    <Route exact path="/site/:id/:tab?" render={() => <ErrorBoundary>
      <Site />
    </ErrorBoundary>}/>
  </Switch>;
}
