import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import MainPageIcon from '@mui/icons-material/Home';
import UnlistedPageIcon from '@mui/icons-material/LinkOff';

const INACTIVE_COLOR = '#6b6d6d';
const NORMAL_COLOR = 'rgb(0 188 212)';
const ICON_STYLE = { marginLeft: 4, width: 16, color: NORMAL_COLOR };

/**
 * @param {object} props
 * @param {string} props.slug
 * @param {boolean} props.isMain
 * @param {boolean} props.isListed
 * @param {boolean} props.isActive
 */
export default function PageSlug(props) {
  /** @type {React.ReactElement} **/
  let icon;
  /** @type {string} **/
  let tooltip;
  if (props.isMain) {
    icon = <MainPageIcon style={ICON_STYLE} />;
    tooltip = 'Home page.';
  } else if (!props.isListed) {
    icon = <UnlistedPageIcon style={ICON_STYLE} />;
    tooltip = 'Shown in site\'s navbar.';
  } else if (!props.isActive) {
    tooltip = 'This page is deactivated and will display a not found message when opened.';
  }

  function renderChip() {
    return <Chip
      component="span"
      style={{
        verticalAlign: 'middle',
        position: 'relative',
        maxWidth: '100%',
        borderColor: props.isActive ? NORMAL_COLOR : INACTIVE_COLOR,
        color: props.isActive ? NORMAL_COLOR : INACTIVE_COLOR,
        marginLeft: 4,
        border: 'none',
        fontWeight: 500,
        cursor: 'pointer',
      }}
      sx={{
        '.MuiChip-label': {
          paddingLeft: !!icon ? '4px' : '8px',
        },
      }}
      size="small"
      label={`/${props.slug}`}
      variant="outlined"
      icon={icon}
    />;
  }

  if (tooltip) {
    return <Tooltip
      title={tooltip}
      placement="right-end"
      arrow
    >{renderChip()}</Tooltip>;
  }

  return renderChip();
}
